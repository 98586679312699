import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from '../utils/query-client';
import React, { useEffect, useState, FC } from 'react';
import { AUTHORITY, Country, MyAccount, QKeyChain, State } from '../utils/queries';
import { API } from '../constants';
import api from 'utils/api';
import { TODO_ANY } from '../types/common';
import { useAuth } from '../hooks';
import { AppLoader } from '../components/ui/Loader/Loader';

type ReactQueryProviderProps = {
  children: React.ReactNode;
};

const prefetchCountriesCache = (): Promise<void> =>
  queryClient
    .fetchQuery(QKeyChain.CountryList(), () => api.get(API.ENDPOINTS.COUNTRIES))
    .then((data: TODO_ANY) => {
      queryClient.setQueryData(
        QKeyChain.CountryMap(),
        data.reduce((acc: { [key: string]: string }, country: Country) => {
          acc[country.id] = country.name;
          return acc;
        }, {})
      );
    });

const prefetchStatesCache = (): Promise<void> =>
  queryClient
    .fetchQuery(QKeyChain.StatesList(), () => api.get(API.ENDPOINTS.STATES))
    .then((data: TODO_ANY) => {
      queryClient.setQueryData(
        QKeyChain.StatesMap(),
        data.reduce((acc: { [key: string]: string }, state: State) => {
          acc[state.stateIso] = state.stateName;
          return acc;
        }, {})
      );
    });

const prefetchRolesCache = (): Promise<void> =>
  queryClient
    .fetchQuery(QKeyChain.RolesList(), () => api.get(`${API.ENDPOINTS.ROLES}?page=0&size=1000`))
    .then((data: TODO_ANY) => {
      queryClient.setQueryData(
        QKeyChain.RolesMap(),
        data.content.reduce((acc: { [key: string]: string }, role: TODO_ANY) => {
          acc[role.id] = role.name;
          return acc;
        }, {})
      );
    });

const prefetchMyAccountCache = (): Promise<MyAccount> =>
  queryClient.fetchQuery(QKeyChain.MyAccount(), () => api.get(API.ENDPOINTS.MY_ACCOUNT));

const ReactQueryProvider: FC<ReactQueryProviderProps> = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      setIsLoading(true);
      prefetchMyAccountCache().then((data) => {
        const promises = [prefetchCountriesCache(), prefetchStatesCache()];

        if (data.authorities.includes(AUTHORITY.LIST_ROLE)) {
          promises.push(prefetchRolesCache());
        }

        Promise.all(promises).then(() => {
          setIsLoading(false);
        });
      });
    } else queryClient.clear();
  }, [isLoggedIn]);

  if (isLoading) return <AppLoader />;

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default ReactQueryProvider;
