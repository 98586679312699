import { parse } from '@rsql/parser';
import {
  ComparisonNode,
  getSelector,
  getValue,
  isComparisonNode,
  isLogicNode,
  LogicNode
} from '@rsql/ast';

const encodeHash = [
  {
    search: '+',
    replace: '%2b'
  }
];

export const encodeText = (str: string) => {
  let tempStr = str;
  encodeHash.forEach((encode) => {
    tempStr = tempStr.replaceAll(encode.search, encode.replace);
  });
  return tempStr;
};

export const parseRsqlToObj = (rsql: string) => {
  const ast = parse(rsql);
  const obj: { [key: string]: string | string[] } = {};

  const getSelectorsAndValues = (node: LogicNode | ComparisonNode) => {
    if (isLogicNode(node)) {
      getSelectorsAndValues(node.left);
      getSelectorsAndValues(node.right);
    } else if (isComparisonNode(node)) {
      const selector = getSelector(node);
      obj[selector] = getValue(node);
    }
  };

  getSelectorsAndValues(ast);

  return obj;
};
