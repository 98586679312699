export const MODULE_PATH = {
  ITEMS: '/items',
  RECEIPTS: '/receipts',
  BUSINESSES: '/businesses',
  ACCOUNT: '/account',
  USERS: '/users',
  ARRIVED_SHIPMENTS: '/arrived-shipments',
  INVENTORY_ITEMS: '/inventory-items',
  ORDERS: '/orders',
  BILLING: '/billing'
};

export default {
  ROOT: '/',

  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot',

  ACCOUNT_SETTINGS: `${MODULE_PATH.ACCOUNT}/settings`,

  ITEMS_LIST: `${MODULE_PATH.ITEMS}/list`,
  ITEMS_CREATE: `${MODULE_PATH.ITEMS}/create`,
  ITEMS_EDIT: `${MODULE_PATH.ITEMS}/:id`,

  RECEIPTS_LIST: `${MODULE_PATH.RECEIPTS}/list`,
  RECEIPT_CREATE: `${MODULE_PATH.RECEIPTS}/create`,
  RECEIPT_EDIT: `${MODULE_PATH.RECEIPTS}/edit/:id`,
  RECEIPT_CARD: `${MODULE_PATH.RECEIPTS}/:id`,
  PUT_AWAY: `${MODULE_PATH.RECEIPTS}/put-away`,

  BUSINESSES_LIST: `${MODULE_PATH.BUSINESSES}/list`,
  BUSINESS_CREATE: `${MODULE_PATH.BUSINESSES}/create`,
  BUSINESS_EDIT: `${MODULE_PATH.BUSINESSES}/edit/:id`,
  BUSINESS_CARD: `${MODULE_PATH.BUSINESSES}/:id`,
  ADD_USER: `${MODULE_PATH.BUSINESSES}/:id/add-user`,

  USERS_LIST: `${MODULE_PATH.USERS}/list`,
  USER_CREATE: `${MODULE_PATH.USERS}/create`,
  USER_EDIT: `${MODULE_PATH.USERS}/edit/:id`,

  ADD_ARRIVED_SHIPMENT_FIND_RECEIPT: `${MODULE_PATH.ARRIVED_SHIPMENTS}/find-receipt`,
  ADD_ARRIVED_SHIPMENT: `${MODULE_PATH.ARRIVED_SHIPMENTS}/:id`,
  USNS: `${MODULE_PATH.ARRIVED_SHIPMENTS}/usns`,

  ORDERS_LIST: `${MODULE_PATH.ORDERS}/list`,
  ORDER_CREATE: `${MODULE_PATH.ORDERS}/create`,
  ORDER_EDIT: `${MODULE_PATH.ORDERS}/edit/:id`,

  ORDER_PICKING: `${MODULE_PATH.ORDERS}/picking/:id`,

  ORDER_PACKING: `${MODULE_PATH.ORDERS}/packing/:id`,

  INVENTORY_ITEMS_LIST: `${MODULE_PATH.INVENTORY_ITEMS}/list`,

  INVOICES_LIST: `${MODULE_PATH.BILLING}/list`,
  SETUP_BILLING: `${MODULE_PATH.BILLING}/setup`,
  INVOICE_CREATE: `${MODULE_PATH.BILLING}/create`,
  INVOICE_EDIT: `${MODULE_PATH.BILLING}/edit/:id`,
  INVOICE_INFO: `${MODULE_PATH.BILLING}/:id`,

  CHARGES_LIST: `${MODULE_PATH.BILLING}/charges`
};
