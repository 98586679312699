import { useContext } from 'react';
import { ZebraContext } from '../provider/ZebraProvider';

const useZebraPrint = () => {
  const context = useContext(ZebraContext);
  if (context === undefined || context === null) {
    throw new Error('useZebraPrint must be used within a ZebraProvider');
  }
  return context;
};

export default useZebraPrint;
