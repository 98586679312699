import AuthGuard from 'routes/route-guard/AuthGuard';

import ItemsModuleRoutes from 'modules/items/routes';
import ReceiptsModuleRoutes from 'modules/receipts/routes';
import BusinessesModuleRoutes from 'modules/business/routes';
import UsersModuleRoutes from 'modules/users/routes';
import PutAwayModuleRoutes from 'modules/put-away/routes';
import AddArrivedModuleRoutes from 'modules/add-arived/routes';
import InventoryModuleRoutes from 'modules/inventory/routes';
import OrdersModuleRoutes from 'modules/orders/routes';
import BillingModuleRoutes from 'modules/billing/routes';
import MainLayout from 'modules/layout/components';

const MainRoutes = () => {
  return {
    path: '/',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      ...ItemsModuleRoutes(),
      ...ReceiptsModuleRoutes(),
      ...BusinessesModuleRoutes(),
      ...UsersModuleRoutes(),
      ...PutAwayModuleRoutes(),
      ...AddArrivedModuleRoutes(),
      ...InventoryModuleRoutes(),
      ...OrdersModuleRoutes(),
      ...BillingModuleRoutes()
    ]
  };
};

export default MainRoutes;
