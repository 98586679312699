import { lazy } from 'react';
import { withSuspense } from '../../components/common';
import { PATH } from '../../constants';
import useAuthority from '../../hooks/useAuthority';

const InvoicesList = withSuspense(lazy(() => import('modules/billing/pages/InvoicesList')));
const CreateInvoice = withSuspense(lazy(() => import('modules/billing/pages/CreateInvoice')));
const SetupBilling = withSuspense(lazy(() => import('modules/billing/pages/SetupBilling')));
const EditInvoice = withSuspense(lazy(() => import('modules/billing/pages/EditInvoice')));
const ChargesList = withSuspense(lazy(() => import('modules/billing/pages/ChargesList')));
const InvoiceInfo = withSuspense(lazy(() => import('modules/billing/pages/InvoiceInfo')));

const Routes = () => {
  const {
    isInvoiceListAllowed,
    isCreateInvoiceAllowed,
    isEditInvoiceAllowed,
    isSetupBillingAllowed
  } = useAuthority();
  const route = [];

  if (isInvoiceListAllowed) {
    route.push(
      {
        path: PATH.INVOICES_LIST,
        element: <InvoicesList />
      },
      {
        path: PATH.CHARGES_LIST,
        element: <ChargesList />
      },
      {
        path: PATH.INVOICE_INFO,
        element: <InvoiceInfo />
      }
    );
  }

  if (isCreateInvoiceAllowed) {
    route.push({
      path: PATH.INVOICE_CREATE,
      element: <CreateInvoice />
    });
  }

  if (isEditInvoiceAllowed) {
    route.push({
      path: PATH.INVOICE_EDIT,
      element: <EditInvoice />
    });
  }

  if (isSetupBillingAllowed) {
    route.push({
      path: PATH.SETUP_BILLING,
      element: <SetupBilling />
    });
  }

  return route;
};

export default Routes;
