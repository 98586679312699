import { IconListDetails, IconSquarePlus } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import { AuthorityContextType } from '../../../provider/AuthorityProvider';

const icons = {
  IconListDetails,
  IconSquarePlus
};

const items = (authorities: AuthorityContextType) => {
  const navItems = [];
  if (authorities.isItemsListAllowed) {
    navItems.push({
      id: 'items-list',
      title: <FormattedMessage id="Item list" />,
      type: 'item',
      icon: icons.IconListDetails,
      url: '/items/list',
      breadcrumbs: false
    });
  }
  // if (authorities.isCreateItemAllowed) {
  //   navItems.push({
  //     id: 'items-create',
  //     title: <FormattedMessage id="Create item" />,
  //     type: 'item',
  //     icon: icons.IconSquarePlus,
  //     url: '/items/create',
  //     breadcrumbs: false
  //   });
  // }

  if (navItems.length === 0) return null;

  return {
    id: 'ui-items',
    title: <FormattedMessage id="items" />,
    type: 'group',
    children: navItems
  };
};

export default items;
