import React, {
  ChangeEvent,
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';
import { Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { Business } from '../../../../modules/business/types';
import useDebounce from '../../../../hooks/useDebounce';
import SelectBusinessList from './SelectBusinessList';

export type BusinessModalHandle = {
  open: () => void;
};

type BusinessModalProps = {
  onChange: (business: Business) => void;
};

const SelectBusinessModal: React.ForwardRefRenderFunction<
  BusinessModalHandle,
  BusinessModalProps
> = ({ onChange }, ref) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 300);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSelect = useCallback((business: Business) => {
    return () => {
      onChange(business);
      handleClose();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose
  }));

  return (
    <Dialog open={isModalOpen} scroll="body" maxWidth="xs" fullWidth onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h3">Select a business</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          minHeight: '10rem',
          flexDirection: 'column',
          gap: 2,
          overflow: 'visible'
        }}
      >
        <TextField
          fullWidth
          label="Search"
          placeholder="Search business"
          value={search}
          onChange={handleSearchChange}
        />
        <SelectBusinessList search={debouncedSearch} onSelect={handleSelect} />
      </DialogContent>
    </Dialog>
  );
};

export default memo(forwardRef(SelectBusinessModal));
