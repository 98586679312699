import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { API } from '../../constants';
import { TODO_ANY } from 'types/common';
import api from '../../utils/api';
import queryString from 'query-string';
import { Category, Item, Subcategory, TableItem } from './types';
import { GeneralError, ListResponse, ListViewQueryParams } from '../../types/react-query';

export const QKeys = {
  ItemList: 'item-list',
  Item: 'item',
  Category: 'category',
  SubCategory: 'sub-category'
};

export const QKeyChain = {
  ItemById: (itemId?: string) => [QKeys.Item, { itemId }],
  ItemList: (params: ListViewQueryParams) => [QKeys.ItemList, { ...params }],
  CategoryList: () => [QKeys.Category],
  CategoryById: (categoryId?: string) => [QKeys.Category, { categoryId }],
  SubCategoryList: (categoryId: string) => [QKeys.SubCategory, { categoryId }]
};

export const useGetItems = <
  TQueryFnData = ListResponse<TableItem>,
  TError = GeneralError,
  TData = TQueryFnData
>(
  params: ListViewQueryParams,
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.ItemList(params),
    () => {
      const queryParams = queryString.stringify({
        ...params,
        sort: params.sort || 'created,desc'
      });
      return api.get(`${API.ENDPOINTS.ITEMS}?${queryParams}`);
    },
    {
      ...options,
      notifyOnChangeProps: ['data', 'isLoading', 'isError']
    }
  );
};

export const useGetItem = <TQueryFnData = TableItem, TError = GeneralError, TData = TQueryFnData>(
  params: { id?: string },
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.ItemById(params.id),
    () => {
      return api.get(`${API.ENDPOINTS.ITEMS}/${params.id}`);
    },
    {
      ...options,
      refetchOnWindowFocus: false
    }
  );
};

export const useCreateItem = (
  options?: Omit<UseMutationOptions<Item, GeneralError, Partial<Item>>, 'mutationFn'>
) => useMutation((body) => api.post(API.ENDPOINTS.ITEMS, body), options);

type UpdateItemVariables = {
  id: string;
  body: Partial<Item>;
};

export const useUpdateItem = (
  options?: Omit<UseMutationOptions<Item, GeneralError, UpdateItemVariables>, 'mutationFn'>
) => useMutation(({ id, body }) => api.put(`${API.ENDPOINTS.ITEMS}/${id}`, body), options);

type DeleteItemVariables = string;

export const useDeleteItem = (
  options?: Omit<UseMutationOptions<TODO_ANY, GeneralError, DeleteItemVariables>, 'mutationFn'>
) =>
  useMutation((id: string) => {
    return api.delete(`${API.ENDPOINTS.ITEMS}/${id}`);
  }, options);

export const useGetCategories = <
  TQueryFnData = ListResponse<Category>,
  TError = GeneralError,
  TData = TQueryFnData
>(
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.CategoryList(),
    () => {
      return api.get(API.ENDPOINTS.CATEGORIES);
    },
    {
      ...options,
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );
};

export const useGetCategory = <
  TQueryFnData = Category,
  TError = GeneralError,
  TData = TQueryFnData
>(
  params: { id?: string },
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.CategoryById(params.id),
    () => {
      return api.get(`${API.ENDPOINTS.CATEGORIES}/${params.id}`);
    },
    {
      ...options,
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );
};

export const useGetSubcategories = <
  TQueryFnData = Subcategory[],
  TError = GeneralError,
  TData = TQueryFnData
>(
  params: { categoryId: string },
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.SubCategoryList(params.categoryId),
    () => {
      return api.get(`${API.ENDPOINTS.CATEGORIES}/${params.categoryId}/items`);
    },
    {
      ...options,
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );
};
