export enum FileType {
  ZPL = 0,
  PDF = 1
}

export enum UsnType {
  BIN = 0,
  TOTE = 1,
  CELL = 2,
  RECEIPT_ITEM = 3
}

export const USN_TYPE_MAP = {
  [UsnType.BIN]: 'Bin',
  [UsnType.CELL]: 'Cell',
  [UsnType.TOTE]: 'Tote',
  [UsnType.RECEIPT_ITEM]: 'Receipt item'
} as {
  [key: string]: string;
};

export type USN = {
  id: string;
  created: Date;
  updated: Date;
  creator: string;
  updater: string;
  value: string;
  url: string;
  fileType: FileType;
  entityId: string;
  entityType: UsnType;
  entityDesc: string;
  warehouseId: string;
};

export type Scan = {
  id: string;
  deviceCode: string;
  jobId: string;
  barCode: string;
  length: number;
  width: number;
  height: number;
  sizeUnit: string;
  weight: number;
  weightUnit: string;
  status: number;
  statusDescription: string;
  created: Date;
  pictures: string[];
};
