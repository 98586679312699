import { SetQuery } from 'use-query-params';
import { TODO_ANY } from '../types/common';
import { ChangeEvent, MouseEvent } from 'react';

export function usePaginationQueryParams(setQuery: SetQuery<TODO_ANY>) {
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery((oldQuery) => ({
      ...oldQuery,
      size: event.target.value
    }));
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((oldQuery) => ({
      ...oldQuery,
      page: newPage
    }));
    window.scrollTo(0, 0);
  };

  return { handleLimitChange, handlePageChange };
}
