import React, { useImperativeHandle, forwardRef, memo, useState } from 'react';
import {
  Box,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';
import CategoryRow from './components/CategoryRow';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useGetCategories, useGetSubcategories } from '../../../modules/items/queries';
import { Category, Subcategory } from '../../../modules/items/types';

export type CategoryModalHandle = {
  open: () => void;
};

type CategoryModalProps = {
  onChange: (item: Subcategory) => void;
};

const SelectCategoryModal: React.ForwardRefRenderFunction<
  CategoryModalHandle,
  CategoryModalProps
> = ({ onChange }, ref) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const handleSelectSubcategory = (item: Subcategory) => () => {
    onChange(item);
    handleClose();
  };

  const handleResetSubcategory = () => {
    setSelectedCategory(null);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    handleResetSubcategory();
  };

  const handleSelectCategory = (item: Category) => {
    return () => {
      setSelectedCategory({
        id: item.id,
        name: item.categoryName
      });
    };
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose
  }));

  const { data: categories, isLoading: isLoadingCategories } = useGetCategories({
    enabled: isModalOpen
  });

  const { data: subcategories, isLoading: isLoadingSubcategories } = useGetSubcategories(
    { categoryId: selectedCategory?.id || '' },
    {
      enabled: !!selectedCategory
    }
  );

  const isLoading = isLoadingCategories || (selectedCategory && isLoadingSubcategories);

  return (
    <Dialog scroll="body" fullWidth maxWidth="xs" open={isModalOpen} onClose={handleClose}>
      <DialogTitle display="flex" alignItems="center" gap={1}>
        {!!selectedCategory && (
          <IconButton onClick={handleResetSubcategory}>
            <ArrowBackIosNewIcon fontSize="small" />
          </IconButton>
        )}
        {selectedCategory?.name || 'Categories:'}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', minHeight: '10rem', flexDirection: 'column' }}>
        {!isLoading ? (
          <Box display="flex" flexDirection="column" gap={1}>
            {!subcategories &&
              categories?.content?.map((item) => (
                <CategoryRow key={item.id} category={item} onClick={handleSelectCategory(item)} />
              ))}
            {!!selectedCategory &&
              subcategories &&
              subcategories.map((item) => (
                <CategoryRow
                  key={item.id}
                  category={item}
                  onClick={handleSelectSubcategory(item)}
                />
              ))}
          </Box>
        ) : (
          <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default memo(forwardRef(SelectCategoryModal));
