import { lazy } from 'react';
import { withSuspense } from '../../components/common';
import { PATH } from '../../constants';
import useAuthority from '../../hooks/useAuthority';

const UsersList = withSuspense(lazy(() => import('modules/users/pages/UsersList')));
const CreateUser = withSuspense(lazy(() => import('modules/users/pages/CreateUser')));

const Routes = () => {
  const { isUsersListAllowed, isCreateUserAllowed } = useAuthority();
  const route = [];

  if (isUsersListAllowed) {
    route.push({
      path: PATH.USERS_LIST,
      element: <UsersList />
    });
  }

  if (isCreateUserAllowed) {
    route.push({
      path: PATH.USER_CREATE,
      element: <CreateUser />
    });
  }

  // if (isEditUserAllowed) {
  //   route.push({
  //     path: PATH.USER_EDIT,
  //     element: <EditUser />
  //   });
  // }

  return route;
};

export default Routes;
