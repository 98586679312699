import { ConfigProps } from 'types/config';

const _getConfigVal = (val: string) => {
  // @ts-ignore
  return window?.__ATLEX_CONFIG__?.[val];
};

export const AWS_API = {
  poolId: _getConfigVal('cognitoUserPoolId'),
  appClientId: _getConfigVal('cognitoClientId')
};

export const getBaseUrl = (): string => _getConfigVal('apiBaseUrl') || 'missingBaseUrl';

export const BASE_PATH = '';

const config: ConfigProps = {
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  navType: 'light',
  presetColor: 'default',
  locale: 'en',
  rtlLayout: false,
  container: false
};

export default config;
