import { lazy } from 'react';

import { withSuspense } from '../../components/common';

import { PATH } from '../../constants';
import useAuthority from '../../hooks/useAuthority';

const BusinessList = withSuspense(lazy(() => import('modules/business/pages/BusinessList')));
const CreateBusiness = withSuspense(lazy(() => import('modules/business/pages/CreateBusiness')));
const EditBusiness = withSuspense(lazy(() => import('modules/business/pages/EditBusiness')));
const BusinessCard = withSuspense(lazy(() => import('modules/business/pages/BusinessCard')));

const Routes = () => {
  const { isBusinessListAllowed, isCreateBusinessAllowed, isEditBusinessAllowed } = useAuthority();
  const route = [];

  if (isBusinessListAllowed) {
    route.push(
      {
        path: PATH.BUSINESSES_LIST,
        element: <BusinessList />
      },
      {
        path: PATH.BUSINESS_CARD,
        element: <BusinessCard />
      }
    );
  }

  if (isCreateBusinessAllowed) {
    route.push({
      path: PATH.BUSINESS_CREATE,
      element: <CreateBusiness />
    });
  }

  if (isEditBusinessAllowed) {
    route.push({
      path: PATH.BUSINESS_EDIT,
      element: <EditBusiness />
    });
  }

  return route;
};

export default Routes;
