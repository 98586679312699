import { Box, Button, Grid, Modal, Stack, Typography } from '@mui/material';
import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  memo,
  useImperativeHandle,
  useState
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Dropzone from '../Dropzone';
import UploadImageCarousel from './components/UploadImageCarousel';
import { useNotifications } from '../../../hooks';
import { TODO_ANY } from 'types/common';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  borderRadius: '10px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3
};

const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

export interface ILoadModalFile {
  url: string;
  fileName?: string;
  type?: string;
}

type UploadImageModalProps = {
  onSubmit: (fileData: ILoadModalFile[]) => void;
};

export type UploadImageModalHandle = {
  open: (images?: ILoadModalFile[]) => void;
};

const ImageUploadModal: ForwardRefRenderFunction<UploadImageModalHandle, UploadImageModalProps> = (
  { onSubmit },
  ref
) => {
  const notification = useNotifications();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [images, changeImages] = useState<ILoadModalFile[]>([]);
  const [currentImageIndex, changeCurrentImageIndex] = useState(0);

  const handleCurrentImageIndexChange = (now?: number) => {
    changeCurrentImageIndex(now || 0);
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose
  }));

  const handleOpen = (images?: ILoadModalFile[]) => {
    if (images) {
      changeImages(images);
    }
    setIsModalOpen(true);
  };

  const handleClose = () => {
    handleReset();
    setIsModalOpen(false);
  };

  const handleReset = () => {
    changeImages([]);
  };

  const handleRemove = () => {
    const newImages = [...images];
    newImages.splice(currentImageIndex, 1);
    changeImages(newImages);
  };

  const handleSave = () => {
    if (images) {
      handleReset();
      onSubmit(images);
      handleClose();
    }
  };

  const handleDrop = (data: File[]) => {
    handleUpdate(data, false);
  };

  const handleUpdate = (data: File[], mergePrevious: boolean) => {
    const parsedFiles = [...data]
      .filter((file) => {
        const fileSize = file.size / 1024 / 1024;
        if (!acceptedImageTypes.includes(file.type)) {
          notification.failure({ msg: `File ${file.name} should be image type.` });
          return false;
        }
        if (fileSize > 2) {
          notification.failure({ msg: `Image ${file.name} should be less than 2mb.` });
          return false;
        }
        return true;
      })
      .map((singleFile) => ({
        url: URL.createObjectURL(singleFile),
        fileName: singleFile.name,
        type: singleFile.type
      }));
    if (mergePrevious) {
      changeImages([...images, ...parsedFiles]);
    } else {
      changeImages(parsedFiles);
    }
  };

  const handleUploadNext = (event: ChangeEvent<TODO_ANY>) => {
    handleUpdate(event.target.files, true);
  };

  const isButtonsDisabled = images.length === 0;
  return (
    <Modal open={isModalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h2" textAlign="center">
              Upload Image
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: 300, overflow: 'hidden' }}>
              {images.length ? (
                <UploadImageCarousel images={images} onChange={handleCurrentImageIndexChange} />
              ) : (
                <DndProvider backend={HTML5Backend}>
                  <Dropzone accept="image/*" onDrop={handleDrop} />
                </DndProvider>
              )}
            </Box>
          </Grid>
          <Grid item container xs={12}>
            <Grid xs={9}>
              <Stack direction="row" spacing={2}>
                <Button disabled={isButtonsDisabled} variant="outlined" onClick={handleReset}>
                  <Typography color="black" fontWeight="bold">
                    Remove all
                  </Typography>
                </Button>
                <Button disabled={isButtonsDisabled} variant="outlined" onClick={handleRemove}>
                  <Typography color="black" fontWeight="bold">
                    Remove
                  </Typography>
                </Button>
                <Button disabled={isButtonsDisabled} variant="outlined">
                  <input
                    type="file"
                    id="upload"
                    multiple
                    accept="image/*"
                    hidden
                    onChange={handleUploadNext}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="upload">
                    <Typography color="black" fontWeight="bold">
                      Upload next
                    </Typography>
                  </label>
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="outlined" onClick={handleSave}>
                <Typography color="black" fontWeight="bold">
                  Save
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default memo(forwardRef(ImageUploadModal));
