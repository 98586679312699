import withSuspense from '../../components/common/withSuspense';
import { lazy } from 'react';
import { PATH } from '../../constants';
import useAuthority from '../../hooks/useAuthority';

const CreateOrder = withSuspense(lazy(() => import('modules/orders/pages/CreateOrder')));
const OrdersList = withSuspense(lazy(() => import('modules/orders/pages/OrdersList')));
const EditOrder = withSuspense(lazy(() => import('modules/orders/pages/EditOrder')));
const OrderPicking = withSuspense(lazy(() => import('modules/orders/pages/OrderPicking')));
const OrderPacking = withSuspense(lazy(() => import('modules/orders/pages/OrderPacking')));

const Routes = () => {
  const {
    isOrderPackingAllowed,
    isOrderPickingAllowed,
    isOrdersListAllowed,
    isEditOrderAllowed,
    isCreateOrderAllowed
  } = useAuthority();
  const route = [];

  if (isOrdersListAllowed) {
    route.push({
      path: PATH.ORDERS_LIST,
      element: <OrdersList />
    });
  }

  if (isCreateOrderAllowed) {
    route.push({
      path: PATH.ORDER_CREATE,
      element: <CreateOrder />
    });
  }

  if (isEditOrderAllowed) {
    route.push({
      path: PATH.ORDER_EDIT,
      element: <EditOrder />
    });
  }

  if (isOrderPickingAllowed) {
    route.push({
      path: PATH.ORDER_PICKING,
      element: <OrderPicking />
    });
  }

  if (isOrderPackingAllowed) {
    route.push({
      path: PATH.ORDER_PACKING,
      element: <OrderPacking />
    });
  }

  return route;
};

export default Routes;
