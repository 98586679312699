import { withSuspense } from '../../components/common';
import { lazy } from 'react';
import { PATH } from '../../constants';
import useAuthority from '../../hooks/useAuthority';

const InventoryItemsList = withSuspense(
  lazy(() => import('modules/inventory/pages/InventoryItemsList'))
);

const Routes = () => {
  const { isInventoryListAllowed } = useAuthority();

  return isInventoryListAllowed
    ? [
        {
          path: PATH.INVENTORY_ITEMS_LIST,
          element: <InventoryItemsList />
        }
      ]
    : [];
};

export default Routes;
