import React from 'react';

import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'store';

type PyaloadType = {
  msg?: string;
};

export default function useNotifications() {
  const dispatch = useDispatch();

  const showSuccess = ({ msg = 'Success', ...restProps }: PyaloadType) => {
    dispatch(
      openSnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true,
        ...restProps,
        open: true,
        message: msg
      })
    );
  };

  const showFailure = ({
    msg = 'Something went wrong. Please try again or contact administrator',
    ...restProps
  }: PyaloadType) => {
    dispatch(
      openSnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true,
        ...restProps,
        open: true,
        message: msg
      })
    );
  };

  return {
    success: showSuccess,
    failure: showFailure
  };
}
