export const COUNTRY_INFO = {
  US: {
    name: 'United States',
    code: 'US',
    phone: /^\+1\d{10}$/,
    mask: '{+1} (000) 000-0000'
  },
  AE: {
    name: 'United Arab Emirates',
    code: 'AE',
    phone: /^\+971\d{9}$/,
    mask: '{+971} (00) 000-0000'
  },
  UZ: {
    name: 'Uzbekistan',
    code: 'UZ',
    phone: /^\+998\d{9}$/,
    mask: '{+998} (00) 000-00-00'
  },
  UA: {
    name: 'Ukraine',
    code: 'UA',
    phone: /^\+380\d{9}$/,
    mask: '{+380} (00) 000-00-00'
  },
  KZ: {
    name: 'Kazakhstan',
    code: 'KZ',
    phone: /^\+7\d{10}$/,
    mask: '{+7} (000) 000-00-00'
  }
};

export type CountryCode = keyof typeof COUNTRY_INFO;
