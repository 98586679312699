import React, { ChangeEvent, useState } from 'react';
import MainCard from '../../../components/ui/cards/MainCard';
import { useNotifications, useZebraPrint } from '../../../hooks';
import { getPrivateImage } from '../../../utils/request';
import axios from 'axios';
import { MESSAGES } from '../../../constants';
import { Grid, MenuItem, TextField } from '@mui/material';
import { GeneralButton } from '../../../components/common/Buttons/Buttons';
import { GenerateUsnModal } from '../../../components/common';

const PrintTest = () => {
  const notification = useNotifications();
  const { localDevices, defaultDevice, printRemoteFile } = useZebraPrint();

  const [url, setUrl] = useState<string>('');
  const [selectedDevice, setSelected] = useState<string | undefined>(defaultDevice?.name);

  const handleSelectDevice = (event: ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.value);
  };

  const handlePrint = async () => {
    try {
      const device = localDevices.find((zebraDevice) => zebraDevice.name === selectedDevice);
      if (device) {
        const { url: fileUrl } = await getPrivateImage(url);
        const response = await axios.get(fileUrl, { responseType: 'blob' });
        await printRemoteFile(device, response.data).catch(() => {
          notification.failure({ msg: MESSAGES.ZEBRA_PRINTING_ERROR });
        });
      }
    } catch (ex) {
      notification.failure({ msg: MESSAGES.ERROR });
    }
  };

  return (
    <MainCard title="Printer Test">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="File url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            value={selectedDevice}
            onChange={handleSelectDevice}
            label="Please select zebra printer"
          >
            {localDevices.map((device) => (
              <MenuItem key={device.name} value={device.name}>
                {device.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <GeneralButton variant="outlined" onClick={handlePrint}>
            Print file
          </GeneralButton>
        </Grid>
        <Grid item xs={12}>
          <GenerateUsnModal>
            <GeneralButton variant="outlined">Generate USN</GeneralButton>
          </GenerateUsnModal>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default PrintTest;
