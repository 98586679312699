import { useNavigate, useRoutes } from 'react-router-dom';

import MainRoutes from './MainRoutes';
import AuthRoutes from './AuthRoutes';
import MainCard from '../components/ui/cards/MainCard';
import { Button } from '@mui/material';

const NotFound = () => {
  const navigate = useNavigate();
  const handleGoBack = () => navigate(-1);
  return (
    <MainCard title="The page was not found or not the necessary rights">
      <Button onClick={handleGoBack}>Go back</Button>
    </MainCard>
  );
};

export default function Routes() {
  return useRoutes([
    AuthRoutes,
    MainRoutes(),
    {
      path: '*',
      element: <NotFound />
    }
  ]);
}
