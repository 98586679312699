import React, { createContext, ReactNode, useEffect, useReducer } from 'react';

// third-party
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoUserAttribute,
  AuthenticationDetails
} from 'amazon-cognito-identity-js';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import { Loader } from 'components/ui';
import { AWS_API } from 'config';
import { AWSCognitoContextType, InitialLoginContextProps } from 'modules/auth/types';
import storage from '../utils/storage';

// constant
const initialState: InitialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

export const userPool = new CognitoUserPool({
  UserPoolId: AWS_API.poolId || '',
  ClientId: AWS_API.appClientId || ''
});

let sessionCognitoUser: CognitoUser | null = null;
let sessionUserAttributes: CognitoUserAttribute[] = [];

export const getCognitoUserPool = () => {
  return userPool;
};

export const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    storage.set('serviceToken', serviceToken);
  } else {
    storage.remove('serviceToken');
  }
};

// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //
const AWSCognitoContext = createContext<AWSCognitoContextType | null>(null);

export const AWSCognitoProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = storage.get('serviceToken');
        if (serviceToken) {
          setSession(serviceToken);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: {
                name: 'Betty'
              }
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = (
    email: string,
    password: string
  ): Promise<{
    session?: CognitoUserSession;
    newPasswordRequired?: boolean;
  }> =>
    new Promise((resolve, reject) => {
      sessionCognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool
      });

      const authData = new AuthenticationDetails({
        Username: email,
        Password: password
      });

      sessionCognitoUser.authenticateUser(authData, {
        onSuccess: (session: CognitoUserSession) => {
          setSession(session.getAccessToken().getJwtToken());
          resolve({ session });
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: {
                email: authData.getUsername(),
                name: authData.getUsername()
              }
            }
          });
        },
        onFailure: (_err) => {
          reject(_err);
        },
        newPasswordRequired: (userAttributes) => {
          delete userAttributes.email_verified;
          userAttributes.given_name = ' ';
          userAttributes.family_name = ' ';
          sessionUserAttributes = userAttributes;
          resolve({ newPasswordRequired: true });
        }
      });
    });

  const changeTempPassword = (password: string) =>
    new Promise((resolve, reject) => {
      sessionCognitoUser?.completeNewPasswordChallenge(password, sessionUserAttributes, {
        onSuccess: (session: CognitoUserSession) => {
          resolve(session);
        },
        onFailure: (_err) => {
          reject(_err);
        }
      });
    });

  const register = (email: string, password: string, firstName: string, lastName: string) =>
    new Promise((success, rej) => {
      userPool.signUp(
        email,
        password,
        [
          new CognitoUserAttribute({ Name: 'email', Value: email }),
          new CognitoUserAttribute({ Name: 'name', Value: `${firstName} ${lastName}` })
        ],
        [],
        async (err, result) => {
          if (err) {
            rej(err);
            return;
          }
          success(result);
        }
      );
    });

  const logout = () => {
    const loggedInUser = userPool.getCurrentUser();
    if (loggedInUser) {
      setSession(null);
      loggedInUser.signOut();
      dispatch({ type: LOGOUT });
    }
  };

  const sendCode = async (email: string): Promise<any> =>
    new Promise((resolve, reject) => {
      const usr = new CognitoUser({
        Username: email,
        Pool: userPool
      });

      usr.forgotPassword({
        onSuccess: (res) => {
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });

  const resetPassword = async (email: string, code: string, password: string) => {
    return new Promise((resolve, reject) => {
      const usr = new CognitoUser({
        Username: email,
        Pool: userPool
      });

      usr.confirmPassword(code, password, {
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <AWSCognitoContext.Provider
      value={{ ...state, login, logout, register, sendCode, resetPassword, changeTempPassword }}
    >
      {children}
    </AWSCognitoContext.Provider>
  );
};

export default AWSCognitoContext;
