import React, { memo } from 'react';
import { FieldProps, getIn } from 'formik';
import NumberFormat, { InputAttributes } from 'react-number-format';
import {
  TextField,
  FormControl,
  FormHelperText,
  InputAdornment,
  Autocomplete,
  CircularProgress,
  TextFieldProps
} from '@mui/material';
import { DesktopDatePickerProps } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { IMaskMixin } from 'react-imask';
import {
  useGetCountries,
  useGetCountriesMap,
  useGetStates,
  useGetStatesMap
} from '../../utils/queries';
import { TODO_ANY } from '../../types/common';
import { COUNTRY_INFO, REGEXP } from '../../constants';

interface CustomProps {
  onChange: any;
  name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<InputAttributes>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      // @ts-ignore
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        thousandSeparator="."
        decimalSeparator=","
        isNumericString
      />
    );
  }
);

const MaskedTextField = IMaskMixin(({ inputRef, defaultValue, ...otherProps }) => (
  // @ts-ignore
  <TextField {...otherProps} inputRef={inputRef} value={defaultValue} />
));

const FormikPhoneInput: React.FC<FieldProps & TextFieldProps & { countryFieldName?: string }> = ({
  form,
  field,
  label,
  fullWidth = true,
  countryFieldName,
  variant = 'outlined',
  children,
  ...restProps
}) => {
  const fieldError = getIn(form.errors, field.name);
  const showError = getIn(form.touched, field.name) && !!fieldError;

  const DEFAULT_PHONE_MASK = '{+}000000000000000';
  const phoneMask =
    countryFieldName &&
    form.values[countryFieldName] &&
    COUNTRY_INFO[form.values[countryFieldName] as keyof typeof COUNTRY_INFO]
      ? COUNTRY_INFO[form.values[countryFieldName] as keyof typeof COUNTRY_INFO].mask
      : DEFAULT_PHONE_MASK;

  return (
    <FormControl fullWidth={fullWidth}>
      <MaskedTextField
        {...field}
        {...restProps}
        disabled={form.isSubmitting}
        variant={variant}
        // @ts-ignore
        label={label}
        error={showError}
        children={children}
        mask={phoneMask}
        onChange={() => {}}
        onAccept={(value, mask) => {
          field.onChange({ currentTarget: { name: field.name, value: mask.unmaskedValue } });
        }}
        overwrite={true}
        InputLabelProps={field.value ? { shrink: true } : {}}
      />
      {showError && <FormHelperText error>{fieldError}</FormHelperText>}
    </FormControl>
  );
};

const formikAdaptersMemoRules = (prev: any, next: any) => {
  if (prev['field'].value !== next['field'].value) return false;
  if (prev['form'].errors[prev['field'].name] !== next['form'].errors[next['field'].name])
    return false;
  return prev['form'].touched[prev['field'].name] === next['form'].touched[next['field'].name];
};

const FormikTextInput: React.FC<FieldProps & TextFieldProps> = ({
  form,
  field,
  label,
  fullWidth = true,
  variant = 'outlined',
  children,
  ...restProps
}) => {
  const fieldError = getIn(form.errors, field.name);
  const showError = getIn(form.touched, field.name) && !!fieldError;

  const handleChange = (event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (
      event.currentTarget &&
      !REGEXP.LETTERS_NUMBERS_SPECIAL_CHARACTERS.test(event.currentTarget.value)
    ) {
      return;
    }
    field.onChange(event);
  };
  return (
    <FormControl fullWidth={fullWidth}>
      <TextField
        {...field}
        {...restProps}
        disabled={form.isSubmitting}
        variant={variant}
        label={label}
        error={showError}
        onChange={restProps.onChange || handleChange}
        children={children}
      />
      {showError && <FormHelperText error>{fieldError}</FormHelperText>}
    </FormControl>
  );
};

const FormikNumberInput: React.FC<FieldProps & TextFieldProps> = (props) => {
  const { form, field } = props;
  return (
    <FormikTextInput
      type="number"
      sx={{
        '& input[type=number]': {
          '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
        }
      }}
      {...field}
      {...props}
      onClick={() => {
        if (field.value === 0) {
          form.setFieldValue(field.name, Number.NaN);
        }
      }}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        // @ts-ignore
        e.target.value = Number(e.target.value);
        field.onBlur(e);
      }}
    />
  );
};

const FormikSelectInput: React.FC<FieldProps & TextFieldProps> = ({ children, ...props }) => {
  return (
    <FormikTextInput {...props} select>
      {children}
    </FormikTextInput>
  );
};

const FormikCurrencyInput: React.FC<FieldProps & TextFieldProps> = ({ field, form, ...props }) => {
  return (
    <FormikTextInput
      {...props}
      field={field}
      form={form}
      name={props.name}
      id={props.name}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        endAdornment: <InputAdornment position="end">$</InputAdornment>
      }}
      variant="outlined"
      onClick={(e) => {
        if (field.value === 0) {
          form.setFieldValue(field.name, Number.NaN);
        }
      }}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        // @ts-ignore
        if (e.currentTarget.value.length !== 0) {
          // @ts-ignore
          form.setFieldValue(
            field.name,
            Number(e.currentTarget.value.replace(/\./g, '').replace(',', '.'))
          );
        } else {
          form.setFieldValue(field.name, Number.NaN);
        }
      }}
    />
  );
};

const onKeyDown = (e: KeyboardEvent) => {
  e.preventDefault();
};

const FormikDatePickerInput: React.FC<FieldProps & DesktopDatePickerProps<any, any>> = ({
  form,
  field,
  label,
  onChange,
  ...props
}) => {
  return (
    <DesktopDatePicker
      {...props}
      onChange={(value) => {
        form.setFieldValue(field.name, onChange ? onChange(value) : value);
      }}
      inputFormat="MM/dd/yyyy"
      value={field.value}
      label={label}
      // @ts-ignore
      renderInput={(params) => (
        // @ts-ignore
        <FormikTextInput form={form} field={field} {...params} onKeyDown={onKeyDown} />
      )}
    />
  );
};

const FormikCountrySelect: React.FC<FieldProps & TextFieldProps> = memo(
  ({
    form,
    field,
    label,
    fullWidth = true,
    variant = 'outlined',
    onChange = () => {},
    inputProps
  }) => {
    const fieldError = getIn(form.errors, field.name);
    const showError = getIn(form.touched, field.name) && !!fieldError;

    const countriesMap = useGetCountriesMap();
    const { data: countries, isLoading } = useGetCountries();

    return (
      <FormControl fullWidth={fullWidth}>
        <Autocomplete
          options={countries ? countries : []}
          // @ts-ignore
          value={{ name: countriesMap[field.value] || '' }}
          loading={isLoading}
          noOptionsText="No countries"
          // @ts-ignore
          onChange={(event, value) => onChange(value)}
          getOptionLabel={(option: TODO_ANY) => option.name}
          filterSelectedOptions
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              error={showError}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        {showError && <FormHelperText error>{fieldError}</FormHelperText>}
      </FormControl>
    );
  },
  formikAdaptersMemoRules
);

const FormikStatesSelect: React.FC<FieldProps & TextFieldProps> = ({
  form,
  field,
  label,
  fullWidth = true,
  variant = 'outlined',
  onChange = () => {}
}) => {
  const fieldError = getIn(form.errors, field.name);
  const showError = getIn(form.touched, field.name) && !!fieldError;

  const statesMap = useGetStatesMap();
  const { data: states, isLoading } = useGetStates();

  return (
    <FormControl fullWidth={fullWidth}>
      <Autocomplete
        options={
          states
            ? states.map((item) => {
                // @ts-ignore
                const id = item.stateIso;
                // @ts-ignore
                const name = item.stateName;
                return { id, name };
              })
            : []
        }
        // @ts-ignore
        value={{ name: statesMap[field.value] || '' }}
        loading={isLoading}
        noOptionsText="No states"
        // @ts-ignore
        onChange={(event, value) => onChange(value)}
        getOptionLabel={(option: TODO_ANY) => option.name}
        filterSelectedOptions
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant={variant}
            error={showError}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
      {showError && <FormHelperText error>{fieldError}</FormHelperText>}
    </FormControl>
  );
};

const FormikStaticTextInput: React.FC<FieldProps & TextFieldProps> = ({
  form,
  field,
  ...restProps
}) => {
  return (
    <FormikTextInput
      {...restProps}
      form={form}
      field={field}
      onChange={() => {}}
      InputLabelProps={field.value ? { shrink: true } : {}}
    />
  );
};

export {
  FormikCountrySelect,
  FormikTextInput,
  FormikNumberInput,
  FormikSelectInput,
  FormikCurrencyInput,
  FormikDatePickerInput,
  FormikPhoneInput,
  FormikStaticTextInput,
  FormikStatesSelect
};
