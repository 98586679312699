import { GeneralError, ListResponse } from '../types/react-query';
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import api from './api';
import { API } from '../constants';
import { RoleStatus } from '../modules/users/types';

export const QKeys = {
  MyAccount: 'my-account',
  Country: 'country-list',
  CountryMap: 'country-map',
  States: 'states-list',
  StatesMap: 'states-map',
  Roles: 'roles-list',
  RolesMap: 'roles-map'
};

export const QKeyChain = {
  MyAccount: () => [QKeys.MyAccount],
  CountryMap: () => [QKeys.CountryMap],
  CountryList: () => [QKeys.Country],
  StatesList: () => [QKeys.States],
  StatesMap: () => [QKeys.StatesMap],
  RolesList: () => [QKeys.Roles],
  RolesMap: () => [QKeys.RolesMap]
};

export type Country = {
  id: string;
  name: string;
};

export type State = {
  stateIso: string;
  stateName: string;
};

export type Role = {
  id: string;
  authorities: string[];
  name: string;
  status: RoleStatus;
  type: number;
  updated: string;
  updater: string;
  created: string;
  creator: string;
};

export type RoleMap = {
  [key: string]: string;
};

export const useGetCountries = <
  TQueryFnData = Country[],
  TError = GeneralError,
  TData = TQueryFnData
>(
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.CountryList(),
    () => api.get(API.ENDPOINTS.COUNTRIES),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      ...options
    }
  );
};

export const useGetStates = <TQueryFnData = State[], TError = GeneralError, TData = TQueryFnData>(
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.StatesList(),
    () => api.get(API.ENDPOINTS.STATES),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      ...options
    }
  );
};

export const useGetCountriesMap = () => {
  const client = useQueryClient();
  const countryMap = client.getQueryData(QKeyChain.CountryMap());
  if (countryMap) {
    return countryMap;
  }
  return {};
};

export const useGetStatesMap = () => {
  const client = useQueryClient();
  const stateMap = client.getQueryData(QKeyChain.StatesMap());
  if (stateMap) {
    return stateMap;
  }
  return {};
};

export const useGetRolesMap = (): RoleMap => {
  const client = useQueryClient();
  const roleMap = client.getQueryData(QKeyChain.RolesMap()) as RoleMap;
  if (roleMap) {
    return roleMap;
  }
  return {};
};

export const useGetRoles = <
  TQueryFnData = ListResponse<Role>,
  TError = GeneralError,
  TData = TQueryFnData
>(
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.RolesList(),
    () => api.get(API.ENDPOINTS.ROLES),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      ...options
    }
  );
};

export type MyAccount = {
  id: string;
  created: string;
  updated: string;
  creator: string;
  updater: string;
  status: number;
  roleId: string;
  roleName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phone: string;
  birthday: string;
  lang: string;
  photoUrl: string;
  type: AccountType;
  authorities: AUTHORITY[];
  businesses: string[];
  warehouses: string[];
};

export enum AccountType {
  ADMIN = 0,
  WAREHOUSE = 1,
  CUSTOMER = 2
}

export enum AUTHORITY {
  LIST_ROLE = 'LIST_ROLE',
  CREATE_ROLE = 'CREATE_ROLE',
  EDIT_ROLE = 'EDIT_ROLE',
  STATUS_ROLE_ACTIVE = 'STATUS_ROLE_ACTIVE',
  STATUS_ROLE_INACTIVE = 'STATUS_ROLE_INACTIVE',
  LIST_USER = 'LIST_USER',
  CREATE_USER = 'CREATE_USER',
  EDIT_USER = 'EDIT_USER',
  STATUS_USER_ACTIVE = 'STATUS_USER_ACTIVE',
  STATUS_USER_INACTIVE = 'STATUS_USER_INACTIVE',
  LIST_BUSINESS = 'LIST_BUSINESS',
  CREATE_BUSINESS = 'CREATE_BUSINESS',
  EDIT_BUSINESS = 'EDIT_BUSINESS',
  STATUS_BUSINESS_ACTIVE = 'STATUS_BUSINESS_ACTIVE',
  STATUS_BUSINESS_INACTIVE = 'STATUS_BUSINESS_INACTIVE',
  LIST_MANAGE_SEARCH_ADDRESS_BOOK = 'LIST_MANAGE_SEARCH_ADDRESS_BOOK',
  LIST_MANAGE_SEARCH_BUSINESS = 'LIST_MANAGE_SEARCH_BUSINESS',
  LIST_MANAGE_SEARCH_ROLE = 'LIST_MANAGE_SEARCH_ROLE',
  LIST_MANAGE_SEARCH_USER = 'LIST_MANAGE_SEARCH_USER',
  CREATE_MANAGE_SEARCH_ADDRESS_BOOK = 'CREATE_MANAGE_SEARCH_ADDRESS_BOOK',
  CREATE_MANAGE_SEARCH_BUSINESS = 'CREATE_MANAGE_SEARCH_BUSINESS',
  CREATE_MANAGE_SEARCH_ROLE = 'CREATE_MANAGE_SEARCH_ROLE',
  CREATE_MANAGE_SEARCH_USER = 'CREATE_MANAGE_SEARCH_USER',
  EDIT_MANAGE_SEARCH_ADDRESS_BOOK = 'EDIT_MANAGE_SEARCH_ADDRESS_BOOK',
  EDIT_MANAGE_SEARCH_BUSINESS = 'EDIT_MANAGE_SEARCH_BUSINESS',
  EDIT_MANAGE_SEARCH_ROLE = 'EDIT_MANAGE_SEARCH_ROLE',
  EDIT_MANAGE_SEARCH_USER = 'EDIT_MANAGE_SEARCH_USER',
  DELETE_MANAGE_SEARCH_ADDRESS_BOOK = 'DELETE_MANAGE_SEARCH_ADDRESS_BOOK',
  DELETE_MANAGE_SEARCH_BUSINESS = 'DELETE_MANAGE_SEARCH_BUSINESS',
  DELETE_MANAGE_SEARCH_ROLE = 'DELETE_MANAGE_SEARCH_ROLE',
  DELETE_MANAGE_SEARCH_USER = 'DELETE_MANAGE_SEARCH_USER',
  LIST_BIN = 'LIST_BIN',
  CREATE_BIN = 'CREATE_BIN',
  ASSIGN_ITEM_BIN = 'ASSIGN_ITEM_BIN',
  EDIT_BIN = 'EDIT_BIN',
  LIST_CELL = 'LIST_CELL',
  CREATE_CELL = 'CREATE_CELL',
  ASSIGN_BIN_CELL = 'ASSIGN_BIN_CELL',
  ASSIGN_ITEM_CELL = 'ASSIGN_ITEM_CELL',
  EDIT_CELL = 'EDIT_CELL',
  LIST_ITEM = 'LIST_ITEM',
  CREATE_ITEM = 'CREATE_ITEM',
  EDIT_ITEM = 'EDIT_ITEM',
  DELETE_ITEM = 'DELETE_ITEM',
  LIST_RECEIPT = 'LIST_RECEIPT',
  CREATE_RECEIPT = 'CREATE_RECEIPT',
  EDIT_RECEIPT = 'EDIT_RECEIPT',
  STATUS_RECEIPT_ARRIVED = 'STATUS_RECEIPT_ARRIVED',
  STATUS_RECEIPT_CANCELED = 'STATUS_RECEIPT_CANCELED',
  DELETE_RECEIPT = 'DELETE_RECEIPT',
  LIST_INVENTORY_ITEM = 'LIST_INVENTORY_ITEM',
  LIST_CATEGORY = 'LIST_CATEGORY',
  CREATE_CATEGORY = 'CREATE_CATEGORY',
  EDIT_CATEGORY = 'EDIT_CATEGORY',
  STATUS_CATEGORY_ACTIVE = 'STATUS_CATEGORY_ACTIVE',
  STATUS_CATEGORY_INACTIVE = 'STATUS_CATEGORY_INACTIVE',
  STATUS_CATEGORY_DELETED = 'STATUS_CATEGORY_DELETED',
  LIST_ORDER = 'LIST_ORDER',
  CREATE_ORDER = 'CREATE_ORDER',
  EDIT_ORDER = 'EDIT_ORDER',
  STATUS_ORDER_PICKING = 'STATUS_ORDER_PICKING',
  STATUS_ORDER_PACKING = 'STATUS_ORDER_PACKING',
  STATUS_ORDER_READY_TO_SHIP = 'STATUS_ORDER_READY_TO_SHIP',
  STATUS_ORDER_SHIPPED = 'STATUS_ORDER_SHIPPED',
  STATUS_ORDER_CANCELLED = 'STATUS_ORDER_CANCELLED',
  LIST_TOTE = 'LIST_TOTE',
  CREATE_TOTE = 'CREATE_TOTE',
  EDIT_TOTE = 'EDIT_TOTE',
  CREATE_TOTE_ITEM = 'CREATE_TOTE_ITEM',
  DELETE_TOTE_ITEM = 'DELETE_TOTE_ITEM',
  LIST_BUSINESS_CONFIG = 'LIST_BUSINESS_CONFIG',
  EDIT_BUSINESS_CONFIG = 'EDIT_BUSINESS_CONFIG',
  LIST_INVOICE = 'LIST_INVOICE',
  CREATE_INVOICE = 'CREATE_INVOICE',
  SEND_VIA_EMAIL = 'SEND_VIA_EMAIL',
  EDIT_INVOICE = 'EDIT_INVOICE',
  STATUS_INVOICE_DRAFT = 'STATUS_INVOICE_DRAFT',
  STATUS_INVOICE_ISSUED = 'STATUS_INVOICE_ISSUED',
  STATUS_INVOICE_PAID = 'STATUS_INVOICE_PAID',
  STATUS_INVOICE_CANCELLED = 'STATUS_INVOICE_CANCELLED',
  DELETE_INVOICE = 'DELETE_INVOICE',
  LIST_USN = 'LIST_USN',
  CREATE_USN = 'CREATE_USN'
}

export const useGetMyAccount = <
  TQueryFnData = MyAccount,
  TError = GeneralError,
  TData = TQueryFnData
>(
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.MyAccount(),
    () => api.get(API.ENDPOINTS.MY_ACCOUNT),
    {
      staleTime: 30000,
      cacheTime: Infinity,
      ...options
    }
  );
};
