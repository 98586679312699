import { useNotifications } from 'hooks';
import { createContext, ReactNode, useEffect, useState } from 'react';
import useAuthority from '../hooks/useAuthority';
import { AppLoader } from '../components/ui/Loader/Loader';

type ZebraContextType = {
  isLoading: boolean;
  defaultDevice: ZebraDevice | null;
  localDevices: ZebraDevice[];
  printRemoteFile: (device: ZebraDevice, file: File) => Promise<void>;
};

export const ZebraContext = createContext<ZebraContextType | null>(null);

const Zebra = window.BrowserPrint;

const getLocalDevices = () =>
  new Promise<{ printer: ZebraDevice[] | undefined }>((resolve, reject) => {
    Zebra.getLocalDevices(resolve, reject);
  });

const getDefaultDevice = () =>
  new Promise<ZebraDevice | undefined>((resolve, reject) => {
    Zebra.getDefaultDevice('printer', resolve, reject);
  });

const printRemoteFile = (device: ZebraDevice, file: File) =>
  new Promise<void>((resolve, reject) => {
    return device.sendFile(file, resolve, reject);
  });

const ZebraProvider = ({ children }: { children: ReactNode }) => {
  const notification = useNotifications();
  const { isWarehouseOrAdminUser } = useAuthority();

  const [zebraState, setZebraState] = useState<{
    isLoading: boolean;
    defaultDevice: ZebraDevice | null;
    localDevices: ZebraDevice[];
  }>({
    isLoading: false,
    defaultDevice: null,
    localDevices: []
  });

  useEffect(() => {
    const init = async () => {
      try {
        setZebraState((prev) => ({
          ...prev,
          isLoading: true
        }));
        const [defaultD, localD] = await Promise.all([getDefaultDevice(), getLocalDevices()]);
        setZebraState({
          defaultDevice: defaultD ?? null,
          localDevices: localD.printer ?? [],
          isLoading: false
        });
      } catch (ex) {
        setZebraState((prev) => ({ ...prev, isLoading: false }));
        notification.failure({ msg: 'Error initializing zebra print' });
      }
    };
    if (isWarehouseOrAdminUser) init();
  }, [isWarehouseOrAdminUser]);

  if (zebraState.isLoading) return <AppLoader />;

  return (
    <ZebraContext.Provider
      value={{
        ...zebraState,
        printRemoteFile
      }}
    >
      {children}
    </ZebraContext.Provider>
  );
};

export default ZebraProvider;
