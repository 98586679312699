import { lazy } from 'react';

import { withSuspense } from '../../components/common';

import { PATH } from '../../constants';
import useAuthority from '../../hooks/useAuthority';

const ReceiptsList = withSuspense(lazy(() => import('modules/receipts/pages/ReceiptsList')));
const CreateReceipt = withSuspense(lazy(() => import('modules/receipts/pages/CreateReceipt')));
const EditReceipt = withSuspense(lazy(() => import('modules/receipts/pages/EditReceipt')));
const ReceiptCard = withSuspense(lazy(() => import('modules/receipts/pages/ReceiptCard')));

const Routes = () => {
  const { isReceiptsListAllowed, isCreateReceiptAllowed, isEditReceiptAllowed } = useAuthority();
  const route = [];

  if (isReceiptsListAllowed) {
    route.push(
      {
        path: PATH.RECEIPTS_LIST,
        element: <ReceiptsList />
      },
      {
        path: PATH.RECEIPT_CARD,
        element: <ReceiptCard />
      }
    );
  }

  if (isCreateReceiptAllowed) {
    route.push({
      path: PATH.RECEIPT_CREATE,
      element: <CreateReceipt />
    });
  }

  if (isEditReceiptAllowed) {
    route.push({
      path: PATH.RECEIPT_EDIT,
      element: <EditReceipt />
    });
  }

  return route;
};

export default Routes;
