import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { useGetBusinesses } from '../../../../modules/business/queries';
import { Box, Button, CircularProgress, IconButton, Pagination, Typography } from '@mui/material';
import { Business } from '../../../../modules/business/types';
import { businessListRsqlBuilder } from '../../../../modules/business/query';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconRefresh } from '@tabler/icons';
const businessRowStyles = {
  border: '1px solid #D3D3D3',
  padding: '8px 12px',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'space-between'
};

type SelectBusinessListProps = {
  search: string;
  onSelect: (business: Business) => () => void;
};

const Loading: FC = () => (
  <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
    <CircularProgress />
  </Box>
);

const NotFound: FC = () => (
  <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
    <Typography variant="h4">No results found</Typography>
  </Box>
);

const Error: FC<{ onRefresh: () => void }> = ({ onRefresh }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    flexGrow={1}
    gap={2}
  >
    <Typography variant="h4">Something went wrong</Typography>
    <IconButton onClick={onRefresh}>
      <IconRefresh />
    </IconButton>
  </Box>
);

const SelectBusinessList: FC<SelectBusinessListProps> = ({ search, onSelect }) => {
  const [page, setPage] = useState(1);

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (search) setPage(1);
  }, [search]);

  const {
    data: businesses,
    isLoading,
    isError,
    refetch
  } = useGetBusinesses(
    {
      search: businessListRsqlBuilder({ searchTerm: search }),
      page: page - 1,
      size: 7
    },
    {
      keepPreviousData: true
    }
  );

  if (isError) return <Error onRefresh={() => refetch()} />;

  if (isLoading || !businesses) return <Loading />;

  if (businesses.content.length === 0) return <NotFound />;

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1} flexGrow={1}>
        {businesses.content.map((business) => (
          <Button key={business.id} fullWidth sx={businessRowStyles} onClick={onSelect(business)}>
            <Typography variant="subtitle1">{business.name}</Typography>
            <ArrowForwardIosIcon fontSize="small" />
          </Button>
        ))}
      </Box>
      <Box display="flex" justifyContent="center">
        <Pagination
          page={page}
          count={businesses.totalPages}
          onChange={handleChangePage}
          color="primary"
          variant="outlined"
        />
      </Box>
    </>
  );
};

export default memo(SelectBusinessList);
