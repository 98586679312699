import { createContext, ReactNode } from 'react';

// project import
import defaultConfig from 'config';
import useLocalStorage from 'hooks/useLocalStorage';

// types
import { CustomizationProps } from 'types/config';

// initial state
const initialState: CustomizationProps = {
    ...defaultConfig,
    onChangeLocale: () => {}
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

type ConfigProviderProps = {
    children: ReactNode;
};

function ConfigProvider({ children }: ConfigProviderProps) {
    const [config, setConfig] = useLocalStorage('uppership', {
        locale: initialState.locale
    });

    const onChangeLocale = (locale: string) => {
        setConfig({
            ...config,
            locale
        });
    };

    return (
        <ConfigContext.Provider
            value={{
                ...config,
                onChangeLocale
            }}
        >
            {children}
        </ConfigContext.Provider>
    );
}

export { ConfigProvider, ConfigContext };
