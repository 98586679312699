import React, {
  ChangeEvent,
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';
import useDebounce from '../../../../hooks/useDebounce';
import { Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import SelectWarehouseList from './SelectWarehouseList';

export type WarehouseModalHandle = {
  open: () => void;
};

type WarehouseModalProps = {
  onChange: (warehouse: any) => void;
};

const SelectWarehouseModal: React.ForwardRefRenderFunction<
  WarehouseModalHandle,
  WarehouseModalProps
> = ({ onChange }, ref) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 300);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSelect = useCallback((warehouse: any) => {
    return () => {
      onChange(warehouse);
      handleClose();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose
  }));

  return (
    <Dialog open={isModalOpen} scroll="body" maxWidth="xs" fullWidth onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h3">Select a warehouse</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          minHeight: '10rem',
          flexDirection: 'column',
          gap: 2,
          overflow: 'visible'
        }}
      >
        <TextField
          fullWidth
          label="Search"
          placeholder="Search warehouse"
          value={search}
          onChange={handleSearchChange}
        />
        <SelectWarehouseList onSelect={handleSelect} search={debouncedSearch} />
      </DialogContent>
    </Dialog>
  );
};

export default memo(forwardRef(SelectWarehouseModal));
