import { useContext } from 'react';
import { AuthorityContext } from '../provider/AuthorityProvider';

const useAuthority = () => {
  const context = useContext(AuthorityContext);
  if (context === undefined || context === null) {
    throw new Error('useAuthority must be used within AuthorityProvider');
  }
  return context;
};

export default useAuthority;
