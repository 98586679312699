export const ENDPOINTS = {
  // account
  MY_ACCOUNT: '/settings/api/v1/users/my-account',
  // files
  STORAGE: '/files/api/v1/storage',
  UploadPublic: '/files/api/v1/storage/uploadPublic',
  UploadPrivate: '/files/api/v1/storage/uploadPrivate',
  // items module
  ITEMS: '/warehouse/api/v1/items',
  // receipts module
  RECEIPTS: '/warehouse/api/v1/receipts',
  RECEIPTS_ITEMS: '/warehouse/api/v1/receiptitems',
  USN: '/warehouse/api/v1/usns',
  // businesses module
  BUSINESSES: '/settings/api/v1/businesses',
  // order module
  ORDERS: '/warehouse/api/v1/orders',
  ORDER_ITEMS: '/warehouse/api/v1/orderitems',
  // put-away module
  BINS: '/warehouse/api/v1/bins',
  CELLS: '/warehouse/api/v1/cells',
  TOTES: '/warehouse/api/v1/totes',
  TOTES_ITEMS: '/warehouse/api/v1/toteitems',

  // inventory module
  INVENTORY_ITEMS: '/warehouse/api/v1/inventoryitems',

  // billing module
  BUSINESS_CONFIG: '/warehouse/api/v1/businessconfigs',
  CHARGES: '/warehouse/api/v1/charges',
  INVOICES: '/warehouse/api/v1/invoices',

  // settings module
  COUNTRIES: '/settings/api/v1/dictionary/countries',
  STATES: '/settings/api/v1/dictionary/countries/US/states',
  AUTHORITIES: '/settings/api/v1/authorities',
  ROLES: '/settings/api/v1/roles',
  USERS: '/settings/api/v1/users',
  WAREHOUSES: '/settings/api/v1/warehouses',

  CATEGORIES: '/warehouse/api/v1/categories',

  MARKETPLACES: '/marketplace/api/v1/settings',

  // inventory module
  MERCHANDISE: '/inventory/api/v1/merchandises',
  IMPORT_MERCHANDISE: '/inventory/api/v1/merchandises/import',
  DICTIONARY: '/inventory/api/v1/dictionary',
  PRODUCTS: '/inventory/api/v1/products',
  WRO: '/inventory/api/v1/wros',
  SHIPMENTS: '/inventory/api/v1/shipments',
  BUNDLES: '/inventory/api/v1/bundles',
  KITS: '/inventory/api/v1/kits',

  // auth
  LOGGED_IN_USER_INFO: '/settings/api/v1/auth/userinfo',
  LOGOUT: '/logout',
  SSO: `/login/oauth2/authorization/uppership?redirect_uri=${window.origin}`,

  // upload files
  UPLOAD_FILE: '/files/api/v1/storage/upload',

  DWS_SCANS: '/dws/api/v1/scans'
};

export const CODES = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  CONFLICT: 409
};

export const ERROR_CODES = {
  NOT_UNIQUE: 'NotUnique',
  RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
  INVALID_ADDRESS: 'INVALID_ADDRESS'
};
