import { QueryErrorResetBoundary } from '@tanstack/react-query';
import React, { FC, ReactNode, useEffect, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { GeneralButton } from '../Buttons/Buttons';
import MainCard from '../../ui/cards/MainCard';
import { useLocation } from 'react-router-dom';

const PageErrorBoundary: FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();

  const ref = useRef<ErrorBoundary>(null);

  useEffect(() => {
    ref.current?.resetErrorBoundary();
  }, [location]);

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          ref={ref}
          onReset={reset}
          fallbackRender={({ resetErrorBoundary }) => (
            <MainCard title="There was an error!">
              <GeneralButton variant="contained" onClick={() => resetErrorBoundary()}>
                Try again
              </GeneralButton>
            </MainCard>
          )}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

export default PageErrorBoundary;
