import { GeneralError, ListResponse, ListViewQueryParams } from '../../types/react-query';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from '../../utils/api';
import { API, STORAGE } from '../../constants';
import { Scan, USN } from './types';
import queryString from 'query-string';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { getBaseUrl } from '../../config';
import storage from '../../utils/storage';
import { Bin, Cell } from '../put-away/types';
import { Tote } from '../orders/types';

export const QKeys = {
  UsnList: 'usn-list'
};

export const QKeyChain = {
  UsnList: (params?: ListViewQueryParams) => [QKeys.UsnList, { ...params }]
};

export const useCreateUSN = (
  options?: Omit<UseMutationOptions<USN, GeneralError, Partial<USN>>, 'mutationFn'>
) =>
  useMutation((body) => {
    return api.post(API.ENDPOINTS.USN, body);
  }, options);

export const useCreateBin = (
  options?: Omit<UseMutationOptions<Bin, GeneralError, {}>, 'mutationFn'>
) =>
  useMutation((body) => {
    return api.post(API.ENDPOINTS.BINS, body);
  }, options);

export const useCreateTote = (
  options?: Omit<UseMutationOptions<Tote, GeneralError, {}>, 'mutationFn'>
) =>
  useMutation((body) => {
    return api.post(API.ENDPOINTS.TOTES, body);
  }, options);

export const useCreateCell = (
  options?: Omit<UseMutationOptions<Cell, GeneralError, {}>, 'mutationFn'>
) =>
  useMutation((body) => {
    return api.post(API.ENDPOINTS.CELLS, body);
  }, options);

export const useGetDWSScans = (
  options?: Omit<
    UseMutationOptions<AxiosResponse<ListResponse<Scan>>, AxiosError, string>,
    'mutationFn'
  >
) =>
  useMutation((barcode) => {
    const queryParams = queryString.stringify({
      search: `barcode==${barcode}`
    });
    return axios.get(`${getBaseUrl()}${API.ENDPOINTS.DWS_SCANS}?${queryParams}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get(STORAGE.AUTH_TOKEN)}`
      }
    });
  }, options);

export const useGetUSNS = <
  TQueryFnData = ListResponse<USN>,
  TError = GeneralError,
  TData = TQueryFnData
>(
  params?: ListViewQueryParams,
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.UsnList(params),
    () => {
      const queryParams = queryString.stringify({
        ...params,
        sort: params?.sort || 'created,desc'
      });
      return api.get(`${API.ENDPOINTS.USN}?${queryParams}`);
    },
    {
      ...options,
      notifyOnChangeProps: ['data', 'isLoading', 'isError']
    }
  );
};
