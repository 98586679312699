import api from './api';
import { API } from '../constants';
import { ILoadModalFile } from 'components/common/Modals/ImageUploadModal';
import { DocumentType } from '../types/document';

export interface IImage {
  id: string;
  url: string;
  filename: string;
}

export const getPrivateImage = (fileId: string): Promise<IImage> => {
  return api.get(`${API.ENDPOINTS.STORAGE}/url/${fileId}`);
};

export const loadFile = async (
  image: Required<ILoadModalFile>,
  options?: {
    isPublic?: boolean;
    folder?: string;
  }
) => {
  const file = await fetch(image.url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      Origin: window.location.origin
    }
  })
    .then((r) => r.blob())
    .then((blobFile) => new File([blobFile], image.fileName, { type: image.type }));
  const formData = new FormData();
  formData.append('file', file);
  if (options?.folder) {
    formData.append('folder', options.folder);
  }
  if (options?.isPublic !== undefined) {
    formData.append('public', String(options.isPublic));
  } else formData.append('public', 'true');

  const data: IImage = await api.post(API.ENDPOINTS.UPLOAD_FILE, formData);
  return data.url;
};

export const deleteFile = async (fileId: string) => {
  const { data } = await api.delete(`${API.ENDPOINTS.STORAGE}/${fileId}`);
  return data;
};

const deleteImage = (image: string) => {
  const fileId = image.split('/').pop();
  return deleteFile(fileId!);
};

export const compareAndUploadImages = async (
  oldImages: string[],
  newImages: ILoadModalFile[],
  options?: {
    isPublic?: boolean;
    folder?: string;
  }
) => {
  const imagesForDelete = oldImages.filter(
    (image: string) => !newImages.find((newImage: ILoadModalFile) => newImage.url === image)
  );
  const imagesForUpload = newImages.filter(
    (image: ILoadModalFile) => !oldImages.find((oldImage: string) => oldImage === image.url)
  );

  // @ts-ignore
  const imagesForUploadPromises = imagesForUpload.map((image) => loadFile(image, options));
  const imagesForDeletePromises = imagesForDelete.map(deleteImage);

  const uploadedImages = await Promise.all([
    ...imagesForUploadPromises,
    ...imagesForDeletePromises
  ]).then((images) => images.filter(Boolean));

  const newImagesUrls = newImages
    .filter(
      (image: ILoadModalFile) =>
        !imagesForUpload.find((uploadedImage: ILoadModalFile) => uploadedImage.url === image.url)
    )
    .map((image: ILoadModalFile) => image.url)
    .concat(uploadedImages);

  return newImagesUrls.length
    ? [
        {
          documentType: DocumentType.Photo,
          paths: newImagesUrls
        }
      ]
    : null;
};
