// material-ui
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Logo } from '../Logo';
import { CircularProgress } from '@mui/material';
import React from 'react';

// styles
const LoaderWrapper = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1301,
  width: '100%'
});

const FlexLoaderWrapper = styled('div')({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1301,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '2rem'
});

// ==============================|| LOADER ||============================== //

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color="primary" />
  </LoaderWrapper>
);

export const AppLoader = () => {
  return (
    <FlexLoaderWrapper>
      <Logo />
      <CircularProgress size={36} />
    </FlexLoaderWrapper>
  );
};

export default Loader;
