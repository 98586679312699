const GENERIC_API_ERROR = 'Something went wrong. Please try again';

export default {
  ERROR: GENERIC_API_ERROR,

  // auth
  PASSWORD_SUCCESSFULLY_CHANGED: 'Password successfully changed',
  CHECK_YOUR_EMAIL: 'Check mail for code',
  PASSWORD_RESET_REQUIRED: 'Password reset required',
  USER_NEEDS_TO_CHANGE_PASSWORD: 'User needs to change temporary password',

  // items
  ITEM_SUCCESSFULLY_CREATED: 'Item successfully created',
  ITEM_FAILED_TO_CREATE: GENERIC_API_ERROR,
  ITEM_SUCCESSFULLY_UPDATED: 'Item successfully updated',
  ITEM_FAILED_TO_UPDATE: GENERIC_API_ERROR,
  ITEM_SUCCESSFULLY_DELETED: 'Item successfully deleted',
  ITEM_FAILED_TO_DELETE: GENERIC_API_ERROR,

  // receipts
  RECEIPT_SUCCESSFULLY_CREATED: 'Receipt successfully created',
  RECEIPT_FAILED_TO_CREATE: GENERIC_API_ERROR,
  RECEIPT_SUCCESSFULLY_UPDATED: 'Receipt successfully updated',
  RECEIPT_FAILED_TO_UPDATE: GENERIC_API_ERROR,
  RECEIPT_SUCCESSFULLY_DELETED: 'Receipt successfully deleted',
  RECEIPT_FAILED_TO_DELETE: GENERIC_API_ERROR,
  RECEIPT_STATUS_SUCCESSFULLY_UPDATED: 'Receipt status successfully updated',
  RECEIPT_STATUS_FAILED_TO_UPDATE: GENERIC_API_ERROR,
  RECEIPT_STATUS_SUCCESSFULLY_UPDATED_TO_ARRIVED: 'Receipt status successfully updated to arrived',
  RECEIPT_SHIPMENT_DETAILS_SUCCESSFULLY_UPDATED: 'Receipt shipment details successfully updated',
  RECEIPT_SHIPMENT_DETAILS_FAILED_TO_UPDATE: GENERIC_API_ERROR,
  RECEIPT_SHIPMENT_ITEM_SUCCESSFULLY_CREATED: 'Shipment item to receipt successfully added',
  RECEIPT_SHIPMENT_ITEM_FAILED_TO_CREATE: GENERIC_API_ERROR,
  RECEIPT_ITEM_NOT_SKU_FOUND: 'Not found item or receipt item for this sku',
  RECEIPT_ITEM_NOT_FOUND_USN: 'This usn was not found, please enter an existing one',
  RECEIPT_NOT_FOUND_DWS_SHIPMENT_DETAILS:
    'Not found dws shipment details information by tracking number',
  RECEIPT_NOT_FOUND_DWS_SHIPMENT_ITEM: 'Not found dws shipment item information by USN',

  // put_away
  INVALID_BIN_BARCODE: 'Invalid bin barcode',
  INVALID_CELL_BARCODE: 'Invalid cell barcode',
  INVALID_ITEM_BARCODE: 'Invalid item barcode',
  EMPTY_BIN_ERROR: 'Bin must have at least one item',
  ASSIGN_ITEM_TO_BIN_SUCCESSFULLY: 'Item successfully assigned to bin',
  ASSIGN_ITEM_TO_BIN_FAILED: GENERIC_API_ERROR,
  ASSIGN_ITEM_TO_CELL_SUCCESSFULLY: 'Item successfully assigned to cell',
  ASSIGN_ITEM_TO_CELL_FAILED: GENERIC_API_ERROR,
  ASSIGN_BIN_TO_CELL_SUCCESSFULLY: 'Bin successfully assigned to cell',
  ASSIGN_BIN_TO_CELL_FAILED: GENERIC_API_ERROR,

  // businesses
  BUSINESS_SUCCESSFULLY_CREATED: 'Business successfully created',
  BUSINESS_FAILED_TO_CREATE: GENERIC_API_ERROR,
  BUSINESS_SUCCESSFULLY_UPDATED: 'Business successfully updated',
  BUSINESS_FAILED_TO_UPDATE: GENERIC_API_ERROR,
  BUSINESS_USER_SUCCESSFULLY_CREATED: 'User was successfully added to your business',
  BUSINESS_USER_FAILED_TO_CREATE: GENERIC_API_ERROR,
  BUSINESS_SUCCESSFULLY_ACTIVATED: 'Business successfully activated',
  BUSINESS_FAILED_TO_ACTIVATE: GENERIC_API_ERROR,
  BUSINESS_SUCCESSFULLY_DEACTIVATED: 'Business successfully deactivated',
  BUSINESS_FAILED_TO_DEACTIVATE: GENERIC_API_ERROR,

  // users
  USER_SUCCESSFULLY_CREATED: 'User successfully created',
  USER_FAILED_TO_CREATE: GENERIC_API_ERROR,
  USER_SUCCESSFULLY_UPDATED: 'User successfully updated',
  USER_FAILED_TO_UPDATE: GENERIC_API_ERROR,
  USER_SUCCESSFULLY_ACTIVATED: 'User successfully activated',
  USER_FAILED_TO_ACTIVATE: GENERIC_API_ERROR,
  USER_SUCCESSFULLY_DEACTIVATED: 'User successfully deactivated',
  USER_FAILED_TO_DEACTIVATE: GENERIC_API_ERROR,

  // orders
  ORDER_SUCCESSFULLY_CREATED: 'Order successfully created',
  ORDER_FAILED_TO_CREATE: GENERIC_API_ERROR,
  ORDER_SUCCESSFULLY_UPDATED: 'Order successfully updated',
  ORDER_FAILED_TO_UPDATE: GENERIC_API_ERROR,
  ORDER_ADDRESS_FAILED_TO_VALIDATE: 'Order delivery address is invalid',

  // order items
  ORDER_ITEM_SUCCESSFULLY_CREATED: 'Order item successfully created',
  ORDER_ITEM_FAILED_TO_CREATE: GENERIC_API_ERROR,
  ORDER_ITEM_SUCCESSFULLY_DELETED: 'Order item successfully deleted',
  ORDER_ITEM_FAILED_TO_DELETE: GENERIC_API_ERROR,

  // picking
  TOTE_ALREADY_ASSIGNED_TO_ANOTHER_ORDER: 'The selected tote is already assigned to another order',
  ADD_TOTE_NOT_FOUND: 'Tote not found or invalid tote barcode',
  TOTE_ITEM_SUCCESSFULLY_CREATED: (barcode: string) => `Item ${barcode} has been assigned to tote`,
  INVENTORY_ITEM_NOT_FOUND: 'Item with this barcode is not founded',
  ORDER_PICKING_SUCCESSFULLY_COMPLETED: 'Order picking successfully completed',

  // packing
  ORDER_ITEM_SUCCESSFULLY_SCANNED: (barcode: string) =>
    `Order item ${barcode} was successfully scanned`,
  ORDER_ITEM_NOT_FOUND: 'No order items in this order with this barcode',
  ORDER_DIMENSIONS_SUCCESSFULLY_SAVED: 'Order dimensions was saved',
  ORDER_PACKING_SUCCESSFULLY_COMPLETED: 'Order packing was completed',
  ORDER_LABEL_SUCCESSFULLY_GENERATED: 'Order label was generated',
  ORDER_LABEL_FAILED_TO_GENERATE: 'Error generating order label',
  ORDER_LABEL_START_PRINTING: 'Start printing order label',
  ORDER_STATUS_SUCCESSFULLY_UPDATED: 'Order status successfully updated',
  ORDER_STATUS_FAILED_TO_UPDATE: GENERIC_API_ERROR,

  // zebra
  ZEBRA_PRINTING_ERROR: 'Error while trying to print, check zebra printer',

  // usn
  USN_SUCCESSFULLY_CREATED: (count: number) => `${count} usn successfully created`,

  // billing
  INVOICE_SUCCESSFULLY_CREATED: 'Invoice successfully created',
  INVOICE_FAILED_TO_CREATE: GENERIC_API_ERROR,
  INVOICE_SUCCESSFULLY_UPDATED: 'Invoice successfully updated',
  INVOICE_FAILED_TO_UPDATE: GENERIC_API_ERROR,
  INVOICE_SUCCESSFULLY_DELETED: 'Invoice successfully deleted',
  INVOICE_FAILED_TO_DELETE: GENERIC_API_ERROR,
  INVOICE_STATUS_SUCCESSFULLY_UPDATED: 'Invoice status successfully updated',
  INVOICE_STATUS_FAILED_TO_UPDATE: GENERIC_API_ERROR,
  INVOICE_FAILED_TO_DOWNLOAD: 'Error while trying to download invoice',
  INVOICE_SUCCESSFULLY_SENT: 'Invoice successfully sent',
  INVOICE_FAILED_TO_SEND: 'Error while trying to send invoice',
  BILLING_SETUP_SUCCESSFULLY_CREATED: 'Business billing was successfully setup',
  BILLING_SETUP_FAILED_TO_CREATE: GENERIC_API_ERROR
};
