import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';
import { Logo } from 'components/ui';
import { PATH } from '../../../../constants';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <Link component={RouterLink} to={PATH.ITEMS_LIST}>
    <Logo />
  </Link>
);

export default LogoSection;
