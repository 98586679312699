import React, { ReactElement, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import { FormikStaticTextInput } from '../FormikAdapters';
import SelectWarehouseModal, {
  WarehouseModalHandle
} from './SelectWarehouseModal/SelectWarehouseModal';
import { LoadingButton } from '@mui/lab';
import { QKeys, useCreateBin, useCreateTote } from '../../../modules/add-arived/queries';
import { useNotifications } from '../../../hooks';
import { MESSAGES } from '../../../constants';
import queryClient from '../../../utils/query-client';
import * as Yup from 'yup';

const initialValues = {
  warehouseId: '',
  warehouseName: ''
};

const CreateBinOrToteModal = ({
  children,
  type
}: {
  children: ReactElement;
  type: 'bin' | 'tote';
}) => {
  const notification = useNotifications();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const formikRef = useRef<FormikProps<typeof initialValues>>(null);
  const warehouseModalRef = useRef<WarehouseModalHandle>(null);
  const handleOpenWarehouseModal = () => {
    warehouseModalRef?.current?.open();
  };
  const handleChangeWarehouse = (warehouse: any) => {
    formikRef.current?.setFieldValue('warehouseId', warehouse.id);
    formikRef.current?.setFieldValue('warehouseName', warehouse.name);
  };

  const { mutate: creatBin, isLoading: isBinCreationLoading } = useCreateBin({
    onSuccess: async () => {
      await queryClient.invalidateQueries([QKeys.UsnList]);
      formikRef.current?.resetForm();
      formikRef.current?.setSubmitting(false);
      handleClose();
    },
    onError: () => {
      notification.failure({ msg: MESSAGES.ERROR });
    }
  });

  const { mutate: createTote, isLoading: isToteCreationLoading } = useCreateTote({
    onSuccess: async () => {
      await queryClient.invalidateQueries([QKeys.UsnList]);
      formikRef.current?.resetForm();
      formikRef.current?.setSubmitting(false);
      handleClose();
    },
    onError: () => {
      notification.failure({ msg: MESSAGES.ERROR });
    }
  });

  const isLoading = isToteCreationLoading || isBinCreationLoading;

  return (
    <>
      {React.cloneElement(children, { onClick: handleClickOpen })}
      <SelectWarehouseModal ref={warehouseModalRef} onChange={handleChangeWarehouse} />
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          warehouseName: Yup.string().required('Required')
        })}
        onSubmit={(values) => (type === 'tote' ? createTote(values) : creatBin(values))}
      >
        {({ submitForm }) => (
          <Form>
            <Dialog open={open} fullWidth maxWidth="xs" onClose={handleClose}>
              <DialogTitle fontWeight="bold">Create {type === 'tote' ? 'tote' : 'bin'}</DialogTitle>
              <DialogContent sx={{ overflow: 'visible' }}>
                <Field
                  component={FormikStaticTextInput}
                  onClick={handleOpenWarehouseModal}
                  name="warehouseName"
                  label="Assigned to warehouse"
                />
              </DialogContent>
              <DialogActions sx={{ pr: 3 }}>
                <Button onClick={handleClose} color="error" variant="outlined">
                  Cancel
                </Button>
                <LoadingButton
                  disabled={isLoading}
                  loading={isLoading}
                  type="submit"
                  onClick={submitForm}
                  variant="outlined"
                >
                  Create
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateBinOrToteModal;
