import { TODO_ANY } from '../../types/common';
import builder from '@rsql/builder';
import { emit } from '@rsql/emitter';
import { encodeText, parseRsqlToObj } from 'utils/query';

export const receiptListRsqlBuilder = (values: TODO_ANY) => {
  const { searchTerm, status } = values;
  const searchValue = searchTerm.trim();

  const expressions = searchValue
    ? [
        builder.eq('number', `*${searchValue}*`),
        builder.eq('businessName', `*${searchValue}*`),
        builder.eq('warehouseName', `*${searchValue}*`),
        builder.eq('trackingNumber', `*${searchValue}*`)
      ]
    : null;

  if (expressions && !status) {
    return encodeText(emit(builder.or(...expressions)));
  }
  if (!expressions && status) {
    return encodeText(emit(builder.eq('status', status)));
  }
  if (expressions && status) {
    return encodeText(emit(builder.and(builder.or(...expressions), builder.eq('status', status))));
  }
  return encodeText('');
};

export const receiptListRsqlParser = (
  search: string | null | undefined,
  initialValues: {
    searchTerm: string;
    status: string | null;
  }
) => {
  const result = {
    ...initialValues
  };
  if (!search) return result;

  const query = parseRsqlToObj(search);
  if (query.number) {
    result.searchTerm = query.number as string;
  }
  if (query.status) {
    result.status = query.status as string;
  }
  return result;
};

export const warehouseListRsqlBuilder = (values: TODO_ANY) => {
  const { searchTerm } = values;
  const searchValue = searchTerm.trim();

  const expressions = searchValue ? [builder.eq('name', `*${searchValue}*`)] : [];

  let rsql = '';

  if (expressions.length > 0) {
    const newExpression = builder.or(...expressions);
    rsql = emit(newExpression);
  }

  return encodeText(rsql);
};
