import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { QueryParamConfigMap, SetQuery } from 'use-query-params';
import { find } from 'lodash';
import useDebounce from './useDebounce';

export type SortValue = {
  id: string;
  desc: boolean;
  count: number;
};
const generateSortQuery = (sortValues: SortValue[]) =>
  sortValues.map((value) => `${value.id},${value.desc ? 'desc' : 'asc'}`);

const parseSortQuery = (sortQuery: string[]) => {
  return sortQuery.map((value) => {
    const [id, desc] = value.split(',');
    return { id, desc: desc === 'desc', count: 1 };
  });
};

export function useSort<T>(
  setQuery: SetQuery<QueryParamConfigMap> | Dispatch<SetStateAction<T>>,
  sort?: string[] | string
) {
  const [sortValues, setSortValues] = useState<SortValue[]>(
    sort && Array.isArray(sort) ? () => parseSortQuery(sort) : []
  );

  const debouncedSortValues = useDebounce(sortValues, 500);

  useEffect(() => {
    const reversedSortValues = [...debouncedSortValues].reverse();

    setQuery((oldQuery) => ({
      ...oldQuery,
      sort: generateSortQuery(reversedSortValues)
    }));
  }, [debouncedSortValues, setQuery]);

  const handleSort = useCallback(
    (key: string) => () => {
      const findSortValueById = (id: string) => {
        return find(sortValues, (value) => value.id === id);
      };

      const sortValue = findSortValueById(key);

      if (sortValue && sortValue.count === 2) {
        setSortValues((oldSortValues) => oldSortValues.filter((value) => value.id !== key));
        return;
      }

      if (sortValue) {
        setSortValues(
          sortValues.map((value) => {
            if (value.id === key) {
              return { ...value, desc: !value.desc, count: value.count + 1 };
            }
            return value;
          })
        );
      } else {
        setSortValues([...sortValues, { id: key, desc: false, count: 1 }]);
      }
    },
    [sortValues]
  );

  return { sortValues, handleSort };
}
