import { useState } from 'react';
import { usePagination } from './usePagination';
import { useSearch } from './useSearch';
import { useSort } from './useSort';

function useTable<T>(searchOptions: {
  rsqlBuilder: (searchValues: T) => string;
  rsqlParser?: (search: string | null | undefined, initialValues: T) => T;
  initialValues: T;
}) {
  const [{ size, page, sort, search }, setQuery] = useState({
    page: 0,
    size: 20,
    sort: '',
    search: ''
  });

  const { searchValues, handleSearch } = useSearch(search, setQuery, searchOptions);
  const { sortValues, handleSort } = useSort(setQuery, sort);
  const { handlePageChange, handleLimitChange } = usePagination(setQuery);

  return {
    size,
    page,
    sort,
    sortValues,
    search,
    searchValues,
    onSort: handleSort,
    onSearch: handleSearch,
    onPageChange: handlePageChange,
    onLimitChange: handleLimitChange
  };
}

export default useTable;
