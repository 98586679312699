import { IconBasket, IconFileInvoice } from '@tabler/icons';
import { PATH } from '../../../constants';
import { AuthorityContextType } from '../../../provider/AuthorityProvider';

const icons = {
  IconBasket,
  IconFileInvoice
};

const orders = (authorities: AuthorityContextType) => {
  const navItems = [];

  if (authorities.isOrdersListAllowed) {
    navItems.push({
      id: 'ui-orders-list',
      title: 'Orders list',
      type: 'item',
      icon: icons.IconFileInvoice,
      url: PATH.ORDERS_LIST,
      breadcrumbs: false
    });
  }

  // if (authorities.isCreateOrderAllowed) {
  //   navItems.push({
  //     id: 'add-order',
  //     title: 'Create order',
  //     type: 'item',
  //     icon: icons.IconBasket,
  //     url: PATH.ORDER_CREATE,
  //     breadcrumbs: false
  //   });
  // }

  if (navItems.length === 0) return null;

  return {
    id: 'ui-orders',
    title: 'Orders',
    type: 'group',
    children: navItems
  };
};
export default orders;
