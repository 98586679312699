import React from 'react';
import { Button, CircularProgress } from '@mui/material';

import { BtnProps } from './types';

const CustomButton: React.FC<BtnProps> = ({
  loading = false,
  disabled,
  sx,
  children,
  ...restProps
}) => {
  return (
    <Button
      sx={{
        height: '36px',
        minWidth: '90px',
        borderRadius: '30px',
        textTransform: 'none',
        ...sx
      }}
      disabled={disabled}
      {...restProps}
    >
      {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : children}
    </Button>
  );
};

const ResetButton: React.FC<BtnProps> = ({
  color = 'error',
  children,
  variant = 'text',
  ...restProps
}) => {
  return (
    <CustomButton {...restProps} type="reset" color={color} variant={variant}>
      {children}
    </CustomButton>
  );
};

const SubmitButton: React.FC<BtnProps> = ({ loading, children, variant, ...restProps }) => {
  return (
    <CustomButton {...restProps} variant={variant} type="submit">
      {loading ? (
        <CircularProgress size={24} sx={{ color: variant === 'contained' ? 'white' : '#2196f3' }} />
      ) : (
        children
      )}
    </CustomButton>
  );
};

const GeneralButton: React.FC<BtnProps> = ({ loading, children, variant, ...restProps }) => {
  return (
    <CustomButton {...restProps} variant={variant} type="button">
      {loading ? (
        <CircularProgress size={24} sx={{ color: variant === 'contained' ? 'white' : '#2196f3' }} />
      ) : (
        children
      )}
    </CustomButton>
  );
};

export { ResetButton, SubmitButton, GeneralButton };
