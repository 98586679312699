import withSuspense from '../../components/common/withSuspense';
import { lazy } from 'react';
import { PATH } from '../../constants';
import PrintTest from './pages/PrintTest';
import useAuthority from '../../hooks/useAuthority';

const AddArrivedShipment = withSuspense(
  lazy(() => import('modules/add-arived/pages/AddArrivedShipment'))
);

const UsnTable = withSuspense(lazy(() => import('modules/add-arived/pages/UsnList')));

const Routes = () => {
  const { isAddArrivedAllowed, isUsnListAllowed } = useAuthority();
  const route = [
    {
      path: `print-test`,
      element: <PrintTest />
    }
  ];

  if (isAddArrivedAllowed) {
    route.push({
      path: PATH.ADD_ARRIVED_SHIPMENT,
      element: <AddArrivedShipment />
    });
  }

  if (isUsnListAllowed) {
    route.push({
      path: PATH.USNS,
      element: <UsnTable />
    });
  }

  return route;
};

export default Routes;
