import { useRef, ChangeEvent, FC } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Box, Typography } from '@mui/material';
import { TODO_ANY } from '../../../types/common';

const uploadBoxStyle = {
  borderRadius: '10px',
  bgcolor: 'background.paper',
  width: '100%',
  maxWidth: 800,
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'transform 0.4s ease'
};

export interface UploadBoxProps {
  onDrop: (file: any) => void;
  accept?: string;
}

const Dropzone: FC<UploadBoxProps> = ({ onDrop, accept }) => {
  const inputFile = useRef<TODO_ANY>(null);

  const onChange = (event: ChangeEvent<any>) => {
    if (event.target.files) {
      onDrop(event.target.files);
    }
    event.target.value = '';
  };

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop({ files }: { files: any[] }) {
      if (onDrop) {
        onDrop(files);
      }
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));

  const onButtonClick = () => {
    inputFile?.current?.click();
  };

  const isActive = canDrop && isOver;
  return (
    <Box
      ref={drop}
      sx={[
        uploadBoxStyle,
        {
          transform: isActive ? 'scale(0.95)' : 'scale(1)',
          border: isActive ? '2px dashed  #000' : '2px dashed #2196f3'
        }
      ]}
    >
      <input
        type="file"
        id="file"
        ref={inputFile}
        hidden
        onChange={onChange}
        accept={accept}
        multiple
      />
      <Box sx={{ cursor: 'pointer' }} onClick={onButtonClick}>
        <Typography color="#000" textAlign="center" fontWeight="bold">
          {isActive ? 'Release to drop' : 'Drag and drop or browse your file'}
        </Typography>
      </Box>
    </Box>
  );
};

export default Dropzone;
