import React from 'react';
import { Button, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CategoryRow: React.FC<{ category: any; onClick: () => void }> = ({ category, onClick }) => {
  return (
    <Button
      fullWidth
      key={category.id}
      sx={{
        border: '1px solid #D3D3D3',
        padding: '8px',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer'
      }}
      onClick={onClick}
    >
      <Typography sx={{ marginLeft: '5px', fontSize: '1rem' }} variant="subtitle1">
        {category.categoryName || category.categoryItemName}
      </Typography>
      <ArrowForwardIosIcon fontSize="small" />
    </Button>
  );
};

export default CategoryRow;
