import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { ChangeEvent, ReactNode, useRef, useState } from 'react';
import { useNotifications, useZebraPrint } from '../../../hooks';
import { QKeys, useCreateUSN } from '../../../modules/add-arived/queries';
import { getPrivateImage } from '../../../utils/request';
import axios from 'axios';
import { FileType, UsnType } from '../../../modules/add-arived/types';
import { MESSAGES } from '../../../constants';
import queryClient from '../../../utils/query-client';
import SelectWarehouseModal, {
  WarehouseModalHandle
} from './SelectWarehouseModal/SelectWarehouseModal';
import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import { FormikNumberInput, FormikStaticTextInput } from '../FormikAdapters';
import * as Yup from 'yup';

const initialValues = {
  count: 1,
  warehouseId: '',
  warehouseName: ''
};

const generateUsnValidationSchema = Yup.object().shape({
  warehouseName: Yup.string().required('Required'),
  count: Yup.number().min(1, 'Count must be greater than or equal to 1').required('Required')
});

const GenerateUsnModal = ({ children }: { children: ReactNode }) => {
  const notification = useNotifications();
  const { localDevices, defaultDevice, printRemoteFile } = useZebraPrint();

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const formikRef = useRef<FormikProps<typeof initialValues>>(null);
  const warehouseModalRef = useRef<WarehouseModalHandle>(null);
  const handleOpenWarehouseModal = () => {
    warehouseModalRef?.current?.open();
  };
  const handleChangeWarehouse = (warehouse: any) => {
    formikRef.current?.setFieldValue('warehouseId', warehouse.id);
    formikRef.current?.setFieldValue('warehouseName', warehouse.name);
  };

  const [selectedDevice, setSelected] = useState<string | undefined>(defaultDevice?.name);

  const handleSelectDevice = (event: ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.value);
  };

  const handleChangeCount = (e: ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value, 10);
    if (isNaN(value)) value = 1;
    if (value > 50) value = 50;
    if (value < 1) value = 1;
    formikRef.current?.setFieldValue('count', value);
  };

  const { mutateAsync: generateUSN } = useCreateUSN({
    onSuccess: async (usn) => {
      if (selectedDevice) {
        const device = localDevices.find((zebraDevice) => zebraDevice.name === selectedDevice);
        if (device) {
          const { url } = await getPrivateImage(usn.url);
          const response = await axios.get(url, { responseType: 'blob' });
          await printRemoteFile(device, response.data).catch(() => {
            throw MESSAGES.ZEBRA_PRINTING_ERROR;
          });
        }
      }
    }
  });

  const onSubmit = async (
    values: typeof initialValues,
    { setSubmitting, resetForm }: FormikHelpers<typeof initialValues>
  ) => {
    try {
      for (let i = 0; i < values.count; i++) {
        await generateUSN({
          fileType: FileType.ZPL,
          entityType: UsnType.RECEIPT_ITEM,
          warehouseId: values.warehouseId
        });
      }
      handleClose();
      resetForm();
      notification.success({ msg: MESSAGES.USN_SUCCESSFULLY_CREATED(values.count) });
    } catch (ex) {
      if (typeof ex === 'string') notification.failure({ msg: ex });
      else notification.failure({ msg: MESSAGES.ERROR });
    } finally {
      await queryClient.invalidateQueries([QKeys.UsnList]);
      setSubmitting(false);
    }
  };

  return (
    <>
      <SelectWarehouseModal ref={warehouseModalRef} onChange={handleChangeWarehouse} />
      {React.cloneElement(children as React.ReactElement, { onClick: handleClickOpen })}
      <Formik
        innerRef={formikRef}
        validationSchema={generateUsnValidationSchema}
        validateOnChange={false}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Dialog open={open} fullWidth maxWidth="xs" onClose={handleClose}>
            <DialogTitle fontWeight="bold">Generate USN</DialogTitle>
            <DialogContent>
              <DialogContentText mb={2}>Select printer and usn labels count</DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={FormikStaticTextInput}
                    onClick={handleOpenWarehouseModal}
                    name="warehouseName"
                    label="Assigned to warehouse"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    component={FormikNumberInput}
                    onChange={handleChangeCount}
                    label="Count"
                    name="count"
                    inputProps={{ min: 1, max: 50 }}
                    sx={{}}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    select
                    fullWidth
                    value={selectedDevice}
                    onChange={handleSelectDevice}
                    label="Please select zebra printer"
                  >
                    <MenuItem value="">none</MenuItem>
                    {localDevices.map((device) => (
                      <MenuItem key={device.name} value={device.name}>
                        {device.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ pr: 3 }}>
              <Button color="error" variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <LoadingButton
                variant="outlined"
                loading={isSubmitting}
                disabled={isSubmitting}
                onClick={submitForm}
                autoFocus
              >
                Generate
              </LoadingButton>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default GenerateUsnModal;
