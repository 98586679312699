import React, { ReactNode } from 'react';
import { AccountType, AUTHORITY, useGetMyAccount } from '../utils/queries';
import { useAuth } from '../hooks';
import { AppLoader } from '../components/ui/Loader/Loader';

export type AuthorityContextType = {
  authorities: AUTHORITY[];
  isAdminUser?: boolean;
  isWarehouseOrAdminUser?: boolean;
  isCreateUsnAllowed?: boolean;
  isRoleListAllowed?: boolean;
  isWarehouseUser: boolean;
  isBusinessUser: boolean;
  isItemsListAllowed?: boolean;
  isCreateItemAllowed?: boolean;
  isEditItemAllowed?: boolean;
  isReceiptsListAllowed?: boolean;
  isCreateReceiptAllowed?: boolean;
  isEditReceiptAllowed?: boolean;
  isOrdersListAllowed?: boolean;
  isCreateOrderAllowed?: boolean;
  isEditOrderAllowed?: boolean;
  isOrderPickingAllowed?: boolean;
  isOrderPackingAllowed?: boolean;
  isInventoryListAllowed?: boolean;
  isInvoiceListAllowed?: boolean;
  isCreateInvoiceAllowed?: boolean;
  isEditInvoiceAllowed?: boolean;
  isDeleteInvoiceAllowed?: boolean;
  isSetupBillingAllowed?: boolean;
  isUsnListAllowed?: boolean;
  isAddArrivedAllowed?: boolean;
  isBusinessListAllowed?: boolean;
  isCreateBusinessAllowed?: boolean;
  isEditBusinessAllowed?: boolean;
  isUsersListAllowed?: boolean;
  isCreateUserAllowed?: boolean;
  isEditUserAllowed?: boolean;
  isPutAwayAllowed?: boolean;
  isDeleteItemAllowed?: boolean;
  isDeleteReceiptAllowed?: boolean;
  isSetReceiptStatusArrivedAllowed?: boolean;
  isSetReceiptStatusCancelledAllowed?: boolean;
  isCreateBinAllowed?: boolean;
  isCreateToteAllowed?: boolean;
  isCreateCellAllowed?: boolean;
};

export const AuthorityContext = React.createContext<null | AuthorityContextType>(null);

const AuthorityProvider = ({ children }: { children: ReactNode }) => {
  const { isLoggedIn } = useAuth();
  const { data: user, isLoading } = useGetMyAccount({
    enabled: isLoggedIn
  });

  if (isLoading && !user && isLoggedIn) return <AppLoader />;

  const isAdminUser = user?.type === AccountType.ADMIN;
  const isWarehouseUser = user?.type === AccountType.WAREHOUSE;
  const isBusinessUser = user?.type === AccountType.CUSTOMER;
  const isWarehouseOrAdminUser = isAdminUser || isWarehouseUser;

  const isRoleListAllowed = user?.authorities.includes(AUTHORITY.LIST_ROLE);

  const isCreateBinAllowed = user?.authorities.includes(AUTHORITY.CREATE_BIN);
  const isCreateToteAllowed = user?.authorities.includes(AUTHORITY.CREATE_TOTE);
  const isCreateCellAllowed = user?.authorities.includes(AUTHORITY.CREATE_CELL);

  const isBusinessListAllowed = user?.authorities.includes(AUTHORITY.LIST_BUSINESS);
  const isCreateBusinessAllowed = user?.authorities.includes(AUTHORITY.CREATE_BUSINESS);
  const isEditBusinessAllowed = user?.authorities.includes(AUTHORITY.EDIT_BUSINESS);

  const isUsersListAllowed = user?.authorities.includes(AUTHORITY.LIST_USER);
  const isCreateUserAllowed = user?.authorities.includes(AUTHORITY.CREATE_USER);
  const isEditUserAllowed = user?.authorities.includes(AUTHORITY.EDIT_USER);

  const isItemsListAllowed = user?.authorities.includes(AUTHORITY.LIST_ITEM);
  const isCreateItemAllowed = user?.authorities.includes(AUTHORITY.CREATE_ITEM);
  const isEditItemAllowed = user?.authorities.includes(AUTHORITY.EDIT_ITEM);
  const isDeleteItemAllowed = user?.authorities.includes(AUTHORITY.DELETE_ITEM);

  const isReceiptsListAllowed = user?.authorities.includes(AUTHORITY.LIST_RECEIPT);
  const isCreateReceiptAllowed = user?.authorities.includes(AUTHORITY.CREATE_RECEIPT);
  const isEditReceiptAllowed = user?.authorities.includes(AUTHORITY.EDIT_RECEIPT);
  const isDeleteReceiptAllowed = user?.authorities.includes(AUTHORITY.DELETE_RECEIPT);
  const isSetReceiptStatusArrivedAllowed = user?.authorities.includes(
    AUTHORITY.STATUS_RECEIPT_ARRIVED
  );
  const isSetReceiptStatusCancelledAllowed = user?.authorities.includes(
    AUTHORITY.STATUS_RECEIPT_CANCELED
  );

  const isCreateUsnAllowed = user?.authorities.includes(AUTHORITY.CREATE_USN);
  const isUsnListAllowed = user?.authorities.includes(AUTHORITY.LIST_USN);
  const isAddArrivedAllowed =
    user?.authorities.includes(AUTHORITY.CREATE_RECEIPT) &&
    user?.authorities.includes(AUTHORITY.EDIT_RECEIPT) &&
    user?.authorities.includes(AUTHORITY.LIST_RECEIPT) &&
    user?.authorities.includes(AUTHORITY.STATUS_RECEIPT_ARRIVED);
  const isPutAwayAllowed = user?.authorities.includes(
    AUTHORITY.ASSIGN_ITEM_BIN || AUTHORITY.ASSIGN_BIN_CELL || AUTHORITY.ASSIGN_ITEM_CELL
  );

  const isOrdersListAllowed = user?.authorities.includes(AUTHORITY.LIST_ORDER);
  const isCreateOrderAllowed =
    user?.authorities.includes(AUTHORITY.CREATE_ORDER) &&
    user?.authorities.includes(AUTHORITY.LIST_BUSINESS);
  const isEditOrderAllowed =
    user?.authorities.includes(AUTHORITY.EDIT_ORDER) &&
    user?.authorities.includes(AUTHORITY.LIST_BUSINESS);
  const isOrderPickingAllowed =
    user?.authorities.includes(AUTHORITY.LIST_ORDER) &&
    user?.authorities.includes(AUTHORITY.STATUS_ORDER_PACKING) &&
    user.authorities.includes(AUTHORITY.LIST_INVENTORY_ITEM) &&
    user?.authorities.includes(AUTHORITY.CREATE_TOTE_ITEM) &&
    user?.authorities.includes(AUTHORITY.LIST_TOTE) &&
    user?.authorities.includes(AUTHORITY.LIST_BUSINESS);
  const isOrderPackingAllowed =
    user?.authorities.includes(AUTHORITY.LIST_ORDER) &&
    user?.authorities.includes(AUTHORITY.EDIT_ORDER) &&
    user?.authorities.includes(AUTHORITY.STATUS_ORDER_READY_TO_SHIP) &&
    user?.authorities.includes(AUTHORITY.STATUS_ORDER_SHIPPED) &&
    user?.authorities.includes(AUTHORITY.DELETE_TOTE_ITEM) &&
    user?.authorities.includes(AUTHORITY.LIST_TOTE);

  const isInventoryListAllowed = user?.authorities.includes(AUTHORITY.LIST_INVENTORY_ITEM);

  const isInvoiceListAllowed = user?.authorities.includes(AUTHORITY.LIST_INVOICE);
  const isCreateInvoiceAllowed =
    user?.authorities.includes(AUTHORITY.CREATE_INVOICE) &&
    user?.authorities.includes(AUTHORITY.LIST_BUSINESS);
  const isEditInvoiceAllowed =
    user?.authorities.includes(AUTHORITY.EDIT_INVOICE) &&
    user?.authorities.includes(AUTHORITY.LIST_BUSINESS);
  const isDeleteInvoiceAllowed = user?.authorities.includes(AUTHORITY.DELETE_INVOICE);
  const isSetupBillingAllowed =
    user?.authorities.includes(AUTHORITY.LIST_BUSINESS_CONFIG) &&
    user?.authorities.includes(AUTHORITY.EDIT_BUSINESS_CONFIG) &&
    user?.authorities.includes(AUTHORITY.LIST_BUSINESS);

  return (
    <AuthorityContext.Provider
      value={{
        authorities: user?.authorities || [],
        isAdminUser,
        isWarehouseOrAdminUser,
        isCreateBinAllowed,
        isCreateToteAllowed,
        isCreateCellAllowed,
        isCreateUsnAllowed,
        isRoleListAllowed,
        isSetReceiptStatusArrivedAllowed,
        isSetReceiptStatusCancelledAllowed,
        isWarehouseUser,
        isBusinessUser,
        isItemsListAllowed,
        isCreateItemAllowed,
        isEditItemAllowed,
        isReceiptsListAllowed,
        isCreateReceiptAllowed,
        isEditReceiptAllowed,
        isOrdersListAllowed,
        isCreateOrderAllowed,
        isEditOrderAllowed,
        isOrderPickingAllowed,
        isOrderPackingAllowed,
        isInventoryListAllowed,
        isInvoiceListAllowed,
        isCreateInvoiceAllowed,
        isEditInvoiceAllowed,
        isDeleteInvoiceAllowed,
        isSetupBillingAllowed,
        isUsnListAllowed,
        isAddArrivedAllowed,
        isBusinessListAllowed,
        isCreateBusinessAllowed,
        isEditBusinessAllowed,
        isUsersListAllowed,
        isCreateUserAllowed,
        isEditUserAllowed,
        isPutAwayAllowed,
        isDeleteItemAllowed,
        isDeleteReceiptAllowed
      }}
    >
      {children}
    </AuthorityContext.Provider>
  );
};

export default AuthorityProvider;
