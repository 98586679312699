import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, IconButton, Pagination, Typography } from '@mui/material';
import { IconRefresh } from '@tabler/icons';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { warehouseListRsqlBuilder } from '../../../../modules/receipts/query';
import { useGetWarehouses } from '../../../../modules/users/queries';

const warehouseRowStyles = {
  border: '1px solid #D3D3D3',
  padding: '8px 12px',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'space-between'
};

type SelectWarehouseListProps = {
  search: string;
  onSelect: (warehouse: any) => () => void;
};

const Loading: FC = () => (
  <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
    <CircularProgress />
  </Box>
);

const NotFound: FC = () => (
  <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
    <Typography variant="h4">No results found</Typography>
  </Box>
);

const Error: FC<{ onRefresh: () => void }> = ({ onRefresh }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    flexGrow={1}
    gap={2}
  >
    <Typography variant="h4">Something went wrong</Typography>
    <IconButton onClick={onRefresh}>
      <IconRefresh />
    </IconButton>
  </Box>
);

const SelectWarehouseList: FC<SelectWarehouseListProps> = ({ search, onSelect }) => {
  const [page, setPage] = useState(1);

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (search) setPage(1);
  }, [search]);

  const {
    data: warehouses,
    isLoading,
    isError,
    refetch
  } = useGetWarehouses(
    {
      search: warehouseListRsqlBuilder({ searchTerm: search }),
      page: page - 1,
      size: 7
    },
    {
      keepPreviousData: true
    }
  );

  if (isError) return <Error onRefresh={() => refetch()} />;

  if (isLoading || !warehouses) return <Loading />;

  if (warehouses.content.length === 0) return <NotFound />;

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1} flexGrow={1}>
        {warehouses.content.map((warehouse) => (
          <Button
            key={warehouse.id}
            fullWidth
            sx={warehouseRowStyles}
            onClick={onSelect(warehouse)}
          >
            <Typography variant="subtitle1">{warehouse.name}</Typography>
            <ArrowForwardIosIcon fontSize="small" />
          </Button>
        ))}
      </Box>
      <Box display="flex" justifyContent="center">
        <Pagination
          page={page}
          count={warehouses.totalPages}
          onChange={handleChangePage}
          color="primary"
          variant="outlined"
        />
      </Box>
    </>
  );
};

export default memo(SelectWarehouseList);
