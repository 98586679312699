import { ILoadModalFile } from '../ImageUploadModal';
import { Paper } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import React, { FC } from 'react';

type UploadImageCarouselProps = {
  images: ILoadModalFile[];
  onChange?: (now?: number | undefined, previous?: number | undefined) => void;
};
const carouselButtonsProps = {
  style: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.4)',
    border: '2px solid #2196f3',
    borderRadius: 10
  }
};

const UploadImageCarousel: FC<UploadImageCarouselProps> = ({ images, onChange }) => {
  return (
    <Carousel
      navButtonsProps={carouselButtonsProps}
      NextIcon={<ArrowForwardIosIcon color="primary" />}
      PrevIcon={<ArrowBackIosNewIcon color="primary" />}
      autoPlay={false}
      animation="fade"
      indicators={false}
      navButtonsAlwaysVisible
      onChange={onChange}
    >
      {images.map((image, i) => (
        <Paper
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          key={i}
        >
          <img src={image.url} alt="item img" height={300} />
        </Paper>
      ))}
    </Carousel>
  );
};

export default UploadImageCarousel;
