import { IconBuildingWarehouse, IconUsers, IconLayoutGridAdd } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import { PATH } from '../../../constants';
import { AuthorityContextType } from '../../../provider/AuthorityProvider';

const icons = {
  IconBuildingWarehouse,
  IconLayoutGridAdd,
  IconUsers
};
const businesses = (authorities: AuthorityContextType) => {
  const navItems = [];

  if (authorities.isBusinessListAllowed) {
    navItems.push({
      id: 'businesses-list',
      title: <FormattedMessage id="Business list" />,
      type: 'item',
      icon: icons.IconBuildingWarehouse,
      url: PATH.BUSINESSES_LIST,
      breadcrumbs: false
    });
  }
  if (authorities.isUsersListAllowed) {
    navItems.push({
      id: 'users-list',
      title: <FormattedMessage id="Users list" />,
      type: 'item',
      icon: icons.IconUsers,
      url: PATH.USERS_LIST,
      breadcrumbs: false
    });
  }

  //
  // if (authorities.isCreateBusinessAllowed) {
  //   navItems.push({
  //     id: 'businesses-create',
  //     title: <FormattedMessage id="Create business" />,
  //     type: 'item',
  //     icon: icons.IconLayoutGridAdd,
  //     url: PATH.BUSINESS_CREATE,
  //     breadcrumbs: false
  //   });
  // }

  //
  // if (authorities.isCreateUserAllowed) {
  //   navItems.push({
  //     id: 'users-create',
  //     title: <FormattedMessage id="Create user" />,
  //     type: 'item',
  //     icon: icons.IconUserPlus,
  //     url: PATH.USER_CREATE,
  //     breadcrumbs: false
  //   });
  // }

  if (navItems.length === 0) return null;

  return {
    id: 'ui-items',
    title: <FormattedMessage id="Businesses & Users" />,
    type: 'group',
    children: navItems
  };
};

export default businesses;
