import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import useDebounce from './useDebounce';

export function useSearch<T, P>(
  search: string,
  setQuery: Dispatch<SetStateAction<P>>,
  {
    rsqlBuilder,
    rsqlParser,
    initialValues
  }: {
    rsqlBuilder: (searchValues: T) => string;
    rsqlParser?: (search: string | null | undefined, initialValues: T) => T;
    initialValues: T;
  }
) {
  const [searchValues, setSearchValues] = useState<T>(
    rsqlParser ? () => rsqlParser(search, initialValues) : initialValues
  );

  const debouncedSearchValues = useDebounce(searchValues, 300);

  useEffect(() => {
    setQuery((oldQuery) => ({
      ...oldQuery,
      search: rsqlBuilder(debouncedSearchValues),
      page: 0
    }));
  }, [debouncedSearchValues, setQuery]);

  const handleSearch = useCallback(
    (key: string, value: string) => {
      setSearchValues((oldSearchValues: T) => ({
        ...oldSearchValues,
        [key]: value
      }));
    },
    [setSearchValues]
  );

  return { searchValues, handleSearch };
}
