const PREFIX = 'uppership_app_';

const get = (key: string) => {
    try {
        const value = localStorage.getItem(`${PREFIX}_${key}`) as string;
        return JSON.parse(value);
    } catch (err) {
        /* tslint:disable */
        console.warn('Error ', err);
        /* tslint:enable */
        return undefined;
    }
};

const set = (key: string, value: string | [] | {} | number | null) => {
    if (value) {
        localStorage.setItem(`${PREFIX}_${key}`, JSON.stringify(value));
    }
};

const remove = (key: string) => {
    localStorage.removeItem(`${PREFIX}_${key}`);
};

export default {
    get,
    set,
    remove
};
