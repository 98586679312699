import { Outlet } from 'react-router-dom';

import NavMotion from '../components/common/NavMotion/NavMotion';
import GuestGuard from './route-guard/GuestGuard';
import AuthModuleRoutes from '../modules/auth/routes';

const AuthRoutes = {
  path: '/',
  element: (
    <NavMotion>
      <GuestGuard>
        <Outlet />
      </GuestGuard>
    </NavMotion>
  ),
  children: [...AuthModuleRoutes]
};

export default AuthRoutes;
