import { NavItemType } from 'types';
import items from './items';
import receipts from './receipts';
import businesses from './businesses';
import orders from './orders';
import inventory from './inventory';
import billings from './billings';
import { AuthorityContextType } from '../../../provider/AuthorityProvider';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: (authorities: AuthorityContextType) => { items: NavItemType[] } = (
  authorities: AuthorityContextType
) => ({
  items: [
    items(authorities),
    receipts(authorities),
    orders(authorities),
    inventory(authorities),
    billings(authorities),
    businesses(authorities)
  ].filter(Boolean) as NavItemType[]
});

export default menuItems;
