import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault
} from 'use-query-params';
import { useSortQueryParam } from './useSortQueryParam';
import { usePaginationQueryParams } from './usePaginationQueryParams';

const useQueryTable = () => {
  const [{ size, page, sort, search }, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 20),
    sort: ArrayParam,
    search: StringParam
  });

  const { sortValues, handleSort } = useSortQueryParam(setQuery, sort);

  const { handlePageChange, handleLimitChange } = usePaginationQueryParams(setQuery);

  return {
    size,
    page,
    sort,
    sortValues,
    search,
    setQuery,
    onSort: handleSort,
    onPageChange: handlePageChange,
    onLimitChange: handleLimitChange
  };
};

export default useQueryTable;
