import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { API } from '../../constants';
import { TODO_ANY } from 'types/common';
import api from '../../utils/api';
import queryString from 'query-string';
import { GeneralError, ListResponse, ListViewQueryParams } from 'types/react-query';
import { Business } from './types';

export const QKeys = {
  BusinessList: 'business-list',
  Business: 'business',
  UsersList: 'users-list'
};

export const QKeyChain = {
  BusinessList: (params?: ListViewQueryParams) => [QKeys.BusinessList, { ...params }],
  BusinessById: (id?: string) => [QKeys.Business, { id }],
  UsersListByBusinessId: (params: Partial<ListViewQueryParams> & { businessId?: string }) => [
    QKeys.UsersList,
    { ...params }
  ]
};

export const useGetBusinesses = <
  TQueryFnData = ListResponse<Business>,
  TError = GeneralError,
  TData = TQueryFnData
>(
  params?: ListViewQueryParams,
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.BusinessList(params),
    () => {
      const queryParams = queryString.stringify({
        ...params,
        sort: params?.sort || 'created,desc'
      });
      return api.get(`${API.ENDPOINTS.BUSINESSES}?${queryParams}`);
    },
    {
      ...options,
      staleTime: Infinity,
      cacheTime: Infinity,
      notifyOnChangeProps: ['data', 'isLoading', 'isError']
    }
  );
};

export const useGetBusiness = <
  TQueryFnData = Business,
  TError = GeneralError,
  TData = TQueryFnData
>(
  params: { id?: string },
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.BusinessById(params.id),
    () => {
      return api.get(`${API.ENDPOINTS.BUSINESSES}/${params.id}`);
    },
    {
      ...options,
      refetchOnWindowFocus: false
    }
  );
};

export const useGetBusinessUsers = <
  TQueryFnData = TODO_ANY,
  TError = GeneralError,
  TData = TQueryFnData
>(
  params: Partial<ListViewQueryParams> & { businessId?: string },
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.UsersListByBusinessId(params),
    () => {
      const { businessId, ...rest } = params;
      const queryParams = queryString.stringify({
        ...rest,
        search: rest.search
          ? `${rest.search.replaceAll(
              ',',
              `;businesses.business.id==${businessId},`
            )};businesses.business.id==${businessId}`
          : `businesses.business.id==${businessId}`,
        sort: rest.sort || 'created,desc'
      });
      return api.get(`${API.ENDPOINTS.USERS}?${queryParams}`);
    },
    options
  );
};

type UpdateBusinessVariables = {
  id: string;
  body: Partial<Business>;
};

export const useUpdateBusiness = (
  options?: Omit<UseMutationOptions<Business, GeneralError, UpdateBusinessVariables>, 'mutationFn'>
) => useMutation(({ id, body }) => api.put(`${API.ENDPOINTS.BUSINESSES}/${id}`, body), options);

export const useCreateBusiness = (
  options?: Omit<UseMutationOptions<Business, GeneralError, TODO_ANY, TODO_ANY>, 'mutationFn'>
) => useMutation((body) => api.post(API.ENDPOINTS.BUSINESSES, body), options);

type ChangeStatusBusinessVariables = {
  items: {
    id: string;
    comment: string;
  }[];
};

export const useDeactivateBusiness = (
  options?: Omit<
    UseMutationOptions<Business, GeneralError, ChangeStatusBusinessVariables, TODO_ANY>,
    'mutationFn'
  >
) => useMutation((body) => api.put(`${API.ENDPOINTS.BUSINESSES}/statuses/inactive`, body), options);

export const useActivateBusiness = (
  options?: Omit<
    UseMutationOptions<Business, GeneralError, ChangeStatusBusinessVariables, TODO_ANY>,
    'mutationFn'
  >
) => useMutation((body) => api.put(`${API.ENDPOINTS.BUSINESSES}/statuses/active`, body), options);

export const useCreateUserToBusiness = (
  options?: Omit<UseMutationOptions<Business, GeneralError, TODO_ANY, TODO_ANY>, 'mutationFn'>
) => useMutation((body) => api.post(API.ENDPOINTS.USERS, body), options);
