import { TODO_ANY } from '../../types/common';
import builder from '@rsql/builder';
import { emit } from '@rsql/emitter';
import { encodeText, parseRsqlToObj } from 'utils/query';

export const businessListRsqlBuilder = (values: TODO_ANY) => {
  const { searchTerm } = values;
  const searchValue = searchTerm.trim();

  const expressions = searchValue
    ? [
        builder.eq('name', `*${searchValue}*`),
        builder.eq('address1', `*${searchValue}*`),
        builder.eq('phone', `*${searchValue}*`),
        builder.eq('email', `*${searchValue}*`)
      ]
    : [];

  let rsql = '';

  if (expressions.length > 0) {
    const newExpression = builder.or(...expressions);
    rsql = emit(newExpression);
  }

  return encodeText(rsql);
};

export const businessListRsqlParser = (
  search: string | null | undefined,
  initialValues: {
    searchTerm: string;
  }
) => {
  const result = {
    ...initialValues
  };
  if (!search) return result;

  const query = parseRsqlToObj(search);
  if (query.name) {
    result.searchTerm = query.name as string;
  }
  return result;
};
