import { IconReceipt, IconReceipt2 } from '@tabler/icons';
import { PATH } from '../../../constants';
import { AuthorityContextType } from '../../../provider/AuthorityProvider';

const icons = {
  IconReceipt,
  IconReceipt2
};

const billings = (authorities: AuthorityContextType) => {
  const navItems = [];

  if (authorities.isInvoiceListAllowed) {
    navItems.push(
      {
        id: 'invoices-list',
        title: 'Invoices list',
        type: 'item',
        icon: icons.IconReceipt,
        url: PATH.INVOICES_LIST,
        breadcrumbs: false
      },
      {
        id: 'charges-list',
        title: 'Charges list',
        type: 'item',
        icon: icons.IconReceipt,
        url: PATH.CHARGES_LIST,
        breadcrumbs: false
      }
    );
  }

  if (authorities.isSetupBillingAllowed) {
    navItems.push({
      id: 'setup-billing',
      title: 'Setup billing',
      type: 'item',
      icon: icons.IconReceipt2,
      url: PATH.SETUP_BILLING,
      breadcrumbs: false
    });
  }

  if (navItems.length === 0) return null;

  return {
    id: 'ui-billings',
    title: 'Billing',
    type: 'group',
    children: navItems
  };
};

export default billings;
