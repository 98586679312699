export enum DocumentType {
  Photo = 1,
  Passport = 2,
  ExceptionPhoto = 3
}

export enum DocumentEntityType {
  PERSON = 0,
  INBOUND = 1,
  OUTBOUND = 2,
  OUTBOUND_ITEM = 3,
  FREIGHT = 4,
  ADDRESS_BOOK = 5
}

export interface IDocument {
  id?: string;
  entityId?: string;
  entityType?: DocumentEntityType;
  documentType: DocumentType;
  pin?: string;
  internalAffairsAuthority?: string; // add desc here pls
  issueDate?: string;
  number?: string;
  paths?: string[]; // links to the docuements on S3
}
