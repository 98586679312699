import { IconClipboardList, IconChecklist, IconClipboardPlus, IconBarcode } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import { PATH } from '../../../constants';
import { AuthorityContextType } from '../../../provider/AuthorityProvider';

const icons = {
  IconClipboardList,
  IconClipboardPlus,
  IconChecklist,
  IconBarcode
};

const receipts = (authorities: AuthorityContextType) => {
  const navItems = [];

  if (authorities.isReceiptsListAllowed) {
    navItems.push({
      id: 'receipts-list',
      title: <FormattedMessage id="Receipt list" />,
      type: 'item',
      icon: icons.IconClipboardList,
      url: PATH.RECEIPTS_LIST,
      breadcrumbs: false
    });
  }

  // if (authorities.isCreateReceiptAllowed) {
  //   navItems.push({
  //     id: 'receipt-create',
  //     title: 'Create receipt',
  //     type: 'item',
  //     icon: icons.IconClipboardPlus,
  //     url: PATH.RECEIPT_CREATE,
  //     breadcrumbs: false
  //   });
  // }

  if (authorities.isPutAwayAllowed) {
    navItems.push({
      id: 'put-away',
      title: <FormattedMessage id="Put away" />,
      type: 'item',
      icon: icons.IconChecklist,
      url: PATH.PUT_AWAY,
      breadcrumbs: false
    });
  }

  if (authorities.isUsnListAllowed) {
    navItems.push({
      id: 'usn-list',
      title: <FormattedMessage id="Barcodes" />,
      type: 'item',
      icon: icons.IconBarcode,
      url: PATH.USNS,
      breadcrumbs: false
    });
  }

  if (navItems.length === 0) return null;

  return {
    id: 'ui-items',
    title: <FormattedMessage id="receipts" />,
    type: 'group',
    children: navItems
  };
};

export default receipts;
