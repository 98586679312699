import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';

import storage from './storage';
import { getCognitoUserPool, setSession } from '../context/AWSCognitoContext';
import { store } from '../store';
import { getBaseUrl } from '../config';

import { LOGOUT } from 'store/actions';
import { STORAGE, API, REGEXP } from '../constants';
import { GeneralError } from '../types/react-query';
import { TODO_ANY } from '../types/common';

const instance = axios.create({
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use((config: any) => {
  const headers = {
    ...config.headers,
    Authorization: `Bearer ${storage.get(STORAGE.AUTH_TOKEN)}`
  };

  config.url = `${getBaseUrl()}${config.url}`;

  const url = new URL(config.url);
  const search = url.searchParams.get('search');

  if (!search) {
    return { ...config, headers };
  }

  url.searchParams.set('search', search.replace(REGEXP.UNDERSCORE_REPLACE, '\\_'));

  return { ...config, url, headers };
});

const handleUnauthorizedError = () => {
  const userPool = getCognitoUserPool();
  const loggedInUser = userPool.getCurrentUser();

  if (loggedInUser) {
    setSession(null);
    loggedInUser.signOut();

    store.dispatch({ type: LOGOUT });

    window.location.href = `${window.location.origin}/login`;
  }
};

instance.interceptors.response.use(
  (res: AxiosResponse) => res.data || res,
  (error: Error) => {
    const { status, data } = get(error, 'response') || {};
    const isUnauthorized = status === API.CODES.UNAUTHORIZED;

    if (isUnauthorized) {
      handleUnauthorizedError();
    }

    return Promise.reject({
      status,
      errors: data.errors,
      message: data.message
    });
  }
);

export const isGeneralError = (value: TODO_ANY): value is GeneralError => {
  if (typeof value === 'object') {
    return ['errors', 'status', 'message'].some((k) => k in value);
  }
  return false;
};

export default instance;
