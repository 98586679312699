// routing
import Routes from 'routes';

// project imports
import ThemeCustomization from 'themes';

// auth provider
import { AWSCognitoProvider as AuthProvider } from 'context/AWSCognitoContext';
import { Locales, NavigationScroll } from 'components/common';
import { SnackBar } from 'components/ui/SnackBar';
import ReactQueryProvider from './provider/ReactQueryProvider';
import ZebraProvider from './provider/ZebraProvider';
import AuthorityProvider from 'provider/AuthorityProvider';
// ==============================|| APP ||============================== //

const App = () => (
  <ThemeCustomization>
    <Locales>
      <NavigationScroll>
        <AuthProvider>
          <ReactQueryProvider>
            <AuthorityProvider>
              <ZebraProvider>
                <Routes />
                <SnackBar />
              </ZebraProvider>
            </AuthorityProvider>
          </ReactQueryProvider>
        </AuthProvider>
      </NavigationScroll>
    </Locales>
  </ThemeCustomization>
);

export default App;
