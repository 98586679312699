import { PATH } from '../../../constants';
import { IconLayoutGrid } from '@tabler/icons';
import { AuthorityContextType } from '../../../provider/AuthorityProvider';

const icons = {
  IconLayoutGrid
};

const inventory = (authorities: AuthorityContextType) =>
  authorities.isInventoryListAllowed
    ? {
        id: 'ui-inventory',
        title: 'Inventory',
        type: 'group',
        children: [
          {
            id: 'inventory-items-list',
            title: 'Inventory items',
            type: 'item',
            icon: icons.IconLayoutGrid,
            url: PATH.INVENTORY_ITEMS_LIST,
            breadcrumbs: false
          }
        ]
      }
    : null;

export default inventory;
