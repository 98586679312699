import React, {
  forwardRef,
  ForwardRefRenderFunction,
  memo,
  useImperativeHandle,
  useState
} from 'react';
import { Dialog, DialogContent } from '@mui/material';
import UploadImageCarousel from './components/UploadImageCarousel';
import { ILoadModalFile } from './ImageUploadModal';
import { TODO_ANY } from '../../../types/common';
import get from 'lodash/get';

type ImageCarouselModalProps = {
  [x: string]: TODO_ANY;
};

export type ImageCarouselModalHandle = {
  open: (data: ILoadModalFile[], key: string) => void;
};

const ImageCarouselModal: ForwardRefRenderFunction<
  ImageCarouselModalHandle,
  ImageCarouselModalProps
> = (props, ref) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [carouselImages, changeCarouselImages] = useState<ILoadModalFile[]>([]);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose
  }));

  const handleOpen = (data: TODO_ANY, key: string) => {
    changeCarouselImages(get(data, key));
    setIsModalOpen(true);
  };

  const handleClose = () => {
    handleReset();
    setIsModalOpen(false);
  };

  const handleReset = () => {
    changeCarouselImages([]);
  };

  return (
    <Dialog open={isModalOpen} maxWidth="sm" fullWidth onClose={handleClose}>
      <DialogContent>
        <UploadImageCarousel images={carouselImages} />
      </DialogContent>
    </Dialog>
  );
};

export default memo(forwardRef(ImageCarouselModal));
