import { QueryParamConfigMap, SetQuery } from 'use-query-params';
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useCallback } from 'react';

export function usePagination<T>(
  setQuery: SetQuery<QueryParamConfigMap> | Dispatch<SetStateAction<T>>
) {
  const handleLimitChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery((oldQuery) => ({
        ...oldQuery,
        size: event.target.value,
        page: 0
      }));
    },
    [setQuery]
  );

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setQuery((oldQuery) => ({
        ...oldQuery,
        page: newPage
      }));
      window.scrollTo(0, 0);
    },
    [setQuery]
  );

  return { handleLimitChange, handlePageChange };
}
