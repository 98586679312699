import { lazy } from 'react';

import { withSuspense } from '../../components/common';

import { PATH } from '../../constants';
import useAuthority from '../../hooks/useAuthority';

const ItemsList = withSuspense(lazy(() => import('modules/items/pages/ItemsList')));
const CreateItem = withSuspense(lazy(() => import('modules/items/pages/CreateItem')));
const EditItem = withSuspense(lazy(() => import('modules/items/pages/EditItem')));

const Routes = () => {
  const { isItemsListAllowed, isCreateItemAllowed, isEditItemAllowed } = useAuthority();
  const route = [];

  if (isItemsListAllowed) {
    route.push({
      path: PATH.ITEMS_LIST,
      element: <ItemsList />
    });
  }
  if (isCreateItemAllowed) {
    route.push({
      path: PATH.ITEMS_CREATE,
      element: <CreateItem />
    });
  }

  if (isEditItemAllowed) {
    route.push({
      path: PATH.ITEMS_EDIT,
      element: <EditItem />
    });
  }

  return route;
};

export default Routes;
