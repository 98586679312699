export default {
  PHONE: /^\+[1-9]\d{10,15}$/,
  EMAIL: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}()?\-"!@#%&\/,><':;|_~`])\S{8,31}$/,
  UNDERSCORE_REPLACE: /_/g,
  LETTERS: /^[A-Za-z\s]+$/,
  LETTERS_NUMBERS: /^[A-Za-z0-9\s]+$/,
  NUMBERS: /^[0-9]+$/,
  LETTERS_NUMBERS_SPECIAL_CHARACTERS: /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?\s]*$/
};
