export const COGNITO_ERROR_CODES = {
  ErrCodeNotAuthorizedException: 'ErrCodeNotAuthorizedException',
  UserNotFoundException: 'UserNotFoundException',
  NotAuthorizedException: 'NotAuthorizedException',
  UsernameExistsException: 'UsernameExistsException',
  InvalidParameterException: 'InvalidParameterException',
  InvalidPasswordException: 'InvalidPasswordException',
  CodeMismatchException: 'CodeMismatchException',
  ExpiredCodeException: 'ExpiredCodeException',
  TooManyRequestsException: 'TooManyRequestsException',
  UserNotConfirmedException: 'UserNotConfirmedException',
  UserLambdaValidationException: 'UserLambdaValidationException',
  PasswordResetRequiredException: 'PasswordResetRequiredException',
  InvalidLambdaResponseException: 'InvalidLambdaResponseException',
  AliasExistsException: 'AliasExistsException',
  TooManyFailedAttemptsException: 'TooManyFailedAttemptsException',
  InvalidSmsRoleAccessPolicyException: 'InvalidSmsRoleAccessPolicyException',
  InvalidSmsRoleTrustRelationshipException: 'InvalidSmsRoleTrustRelationshipException',
  CodeDeliveryFailureException: 'CodeDeliveryFailureException',
  InternalErrorException: 'InternalErrorException',
  UnexpectedLambdaException: 'UnexpectedLambdaException',
  LimitExceededException: 'LimitExceededException'
};

export const COGNITO_ERROR_MESSAGES = {
  [COGNITO_ERROR_CODES.ErrCodeNotAuthorizedException]: 'Not authorized',
  [COGNITO_ERROR_CODES.UserNotFoundException]: 'User not found',
  [COGNITO_ERROR_CODES.NotAuthorizedException]: 'Incorrect username or password',
  [COGNITO_ERROR_CODES.UsernameExistsException]: 'Username already exists',
  [COGNITO_ERROR_CODES.InvalidParameterException]: 'Invalid parameter',
  [COGNITO_ERROR_CODES.InvalidPasswordException]: 'Invalid password',
  [COGNITO_ERROR_CODES.CodeMismatchException]: 'Code mismatch',
  [COGNITO_ERROR_CODES.ExpiredCodeException]: 'Code expired',
  [COGNITO_ERROR_CODES.TooManyRequestsException]: 'Too many requests',
  [COGNITO_ERROR_CODES.InternalErrorException]: 'Internal error',
  [COGNITO_ERROR_CODES.AliasExistsException]: 'Alias already exists',
  [COGNITO_ERROR_CODES.TooManyFailedAttemptsException]: 'Too many failed attempts',
  [COGNITO_ERROR_CODES.InvalidSmsRoleAccessPolicyException]: 'Invalid SMS role access policy',
  [COGNITO_ERROR_CODES.InvalidSmsRoleTrustRelationshipException]:
    'Invalid SMS role trust relationship',
  [COGNITO_ERROR_CODES.CodeDeliveryFailureException]: 'Code delivery failure',
  [COGNITO_ERROR_CODES.UnexpectedLambdaException]: 'Unexpected lambda error',
  [COGNITO_ERROR_CODES.LimitExceededException]: 'Attempt limit exceeded, please try after some time'
};
