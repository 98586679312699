import { withSuspense } from '../../components/common';
import { lazy } from 'react';
import { PATH } from '../../constants';
import useAuthority from '../../hooks/useAuthority';

const PutAway = withSuspense(lazy(() => import('modules/put-away/pages/PutAway')));

const Routes = () => {
  const { isPutAwayAllowed } = useAuthority();
  const route = [];

  if (isPutAwayAllowed) {
    route.push({
      path: PATH.PUT_AWAY,
      element: <PutAway />
    });
  }

  return route;
};

export default Routes;
