import { withSuspense } from '../../components/common';
import { lazy } from 'react';
import { PATH } from '../../constants';

const AuthLogin = withSuspense(lazy(() => import('modules/auth/pages/Login')));
const AuthForgotPassword = withSuspense(lazy(() => import('modules/auth/pages/ForgotPassword')));

const routes = [
  {
    path: PATH.ROOT,
    element: <AuthLogin />
  },
  {
    path: PATH.LOGIN,
    element: <AuthLogin />
  },
  {
    path: PATH.FORGOT_PASSWORD,
    element: <AuthForgotPassword />
  }
];

export default routes;
