import { GeneralError, ListResponse, ListViewQueryParams } from '../../types/react-query';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import queryString from 'query-string';
import api from '../../utils/api';
import { API } from '../../constants';
import { User } from './types';
import { TODO_ANY } from '../../types/common';
import { Business } from '../business/types';

export const QKeys = {
  UsersList: 'users-list',
  User: 'user',
  WarehousesList: 'warehouses-list'
};

export const QKeyChain = {
  UsersList: (params: ListViewQueryParams) => [QKeys.UsersList, { ...params }],
  UserById: (id?: string) => [QKeys.User, id],
  WarehousesList: (params?: ListViewQueryParams) => [QKeys.WarehousesList, { ...params }]
};

export const useGetUsers = <
  TQueryFnData = ListResponse<User>,
  TError = GeneralError,
  TData = TQueryFnData
>(
  params: ListViewQueryParams,
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.UsersList(params),
    () => {
      const queryParams = queryString.stringify({
        ...params,
        sort: params.sort || 'created,desc'
      });
      return api.get(`${API.ENDPOINTS.USERS}?${queryParams}`);
    },
    {
      ...options,
      notifyOnChangeProps: ['data', 'isLoading', 'isError']
    }
  );
};

export const useGetUser = <TQueryFnData = User, TError = GeneralError, TData = TQueryFnData>(
  params: { id?: string },
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.UserById(params.id),
    () => {
      return api.get(`${API.ENDPOINTS.USERS}/${params.id}`);
    },
    {
      ...options,
      refetchOnWindowFocus: false
    }
  );
};

type UpdateUserVariables = {
  id?: string;
  body: Partial<User>;
};

export const useUpdateUser = (
  options?: Omit<UseMutationOptions<User, GeneralError, UpdateUserVariables>, 'mutationFn'>
) => useMutation(({ id, body }) => api.put(`${API.ENDPOINTS.USERS}/${id}`, body), options);

type ChangeStatusUserVariables = {
  items: {
    id: string;
    comment: string;
  }[];
};

export const useDeactivateUser = (
  options?: Omit<
    UseMutationOptions<Business, GeneralError, ChangeStatusUserVariables, TODO_ANY>,
    'mutationFn'
  >
) => useMutation((body) => api.put(`${API.ENDPOINTS.USERS}/statuses/inactive`, body), options);

export const useActivateUser = (
  options?: Omit<
    UseMutationOptions<Business, GeneralError, ChangeStatusUserVariables, TODO_ANY>,
    'mutationFn'
  >
) => useMutation((body) => api.put(`${API.ENDPOINTS.USERS}/statuses/active`, body), options);

export const useGetWarehouses = <
  TQueryFnData = ListResponse<TODO_ANY>,
  TError = GeneralError,
  TData = TQueryFnData
>(
  params?: ListViewQueryParams,
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>(
    QKeyChain.WarehousesList(params),
    () => {
      const queryParams = params ? queryString.stringify(params) : '';
      return api.get(`${API.ENDPOINTS.WAREHOUSES}?${queryParams}`);
    },
    {
      ...options,
      notifyOnChangeProps: ['data', 'isLoading', 'isError']
    }
  );
};

export const useCreateUser = (
  options?: Omit<UseMutationOptions<Business, GeneralError, TODO_ANY, TODO_ANY>, 'mutationFn'>
) => useMutation((body) => api.post(API.ENDPOINTS.USERS, body), options);
